import AppSettings from './app-settings';
import { env, Env } from '@axl/accel-framework/enums';
import sharedSettings from 'appsettings.json';
import devSettings from 'appsettings.dev.json';
import prodSettings from 'appsettings.prod.json';

let envSettings: any;
if (env == Env.dev) {
    envSettings = Object.assign(sharedSettings, devSettings);
} else {
    envSettings = Object.assign(sharedSettings, prodSettings);
}
const appSettings = new AppSettings(envSettings);

export { AppSettings }
export default appSettings;