import Api from 'api';
import { ApiRequest, ApiResponse, HttpMethod, toOptional } from '@axl/accel-framework/api';
import { ContactLicense, ContactLicenseFilter } from '@axl/accel-framework/models';

export default class SubscriptionApi {
    constructor(private api: Api) { }

    public async fetch(request: FetchSubscriptionsRequest) {
        return await this.api.execute(request, FetchSubcriptionsResponse);
    }

    public async fetchItem(request: FetchSubscriptionRequest) {
        return await this.api.execute(request, FetchSubcriptionResponse);
    }

    public async save(request: SaveSubscriptionRequest) {
        return await this.api.execute(request, SaveSubscriptionResponse);
    }
}


export class FetchSubscriptionRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/student/subscription/${this.id}`;

    constructor(public id: string, fields?: string) {
        super();
        this.fields = fields ?? `{
            id,
            beginDate,
            endDate,
            creditEndDate,
            type,
            state,
            product{
                id,
                name,
                subsPrice,
                subsDuration,
                subsDurationType,
                image{
                    id,
                    name,
                    cloudKey,
                    extension,
                    size,
                    type
                }
            },
            currentSuspension{
                id,
                beginDate,
                endDate
            }
        }`;
    }

}

export class FetchSubcriptionResponse extends ApiResponse {
    public subscription: ContactLicense;
    constructor(response: any) {
        super(response);
        this.subscription = ContactLicense.fromJson(this.body);
    }
}


export class FetchSubscriptionsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/student/subscription';
    public body = {
        ...this.filter,
    }

    constructor(public filter: ContactLicenseFilter, fields?: string) {
        super();
        this.fields = fields ?? `{
            id,
            beginDate,
            endDate,
            creditEndDate,
            type,
            state,
            product{
                id,
                name,
                subsPrice,
                subsDuration,
                subsDurationType,
                image{
                    id,
                    name,
                    cloudKey,
                    extension,
                    size,
                    type
                }
            },
            currentSuspension{
                id,
                beginDate,
                endDate
            }
        }`;
    }

}

export class FetchSubcriptionsResponse extends ApiResponse {
    public filter: ContactLicenseFilter;
    public items: ContactLicense[];
    constructor(response: any) {
        super(response);
        this.filter = new ContactLicenseFilter(this.body.filter);
        this.items = this.body.items.map(ContactLicense.fromJson);
    }
}

export class SaveSubscriptionRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/student/subscription';
    public body = {
        id: this.license.id,
        state: toOptional(this.license.state)
    }

    constructor(public license: PartialBut<ContactLicense, 'id'>) {
        super();
    }

}

export class SaveSubscriptionResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}