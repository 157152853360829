import { inject } from 'react-ioc';
import Api from 'api';
import { ContactInFilterRequest } from 'api/methods/service/types/contact-in-filter';
import { RunCustomEventRequest, RunCustomEventRequestModel } from 'api/methods/service/types/run-custom-event';
import { UpdateLocalizationRequest } from 'api/methods/service/types/update-localization';
import { LocalizationStore } from '@axl/accel-framework/stores';
import UserStore from 'stores/UserStore/UserStore';
import { RefreshTokenRequest } from 'api/methods/auth/types/refresh-token';
import { Lang } from '@axl/accel-framework/enums';
import { FetchMeRequest } from 'api/methods/auth/types/me';
import { KeepaliveRequest } from 'api/methods/service/types/keepalive';
import { SelectLangRequest } from 'api/methods/student/student/types/select-lang';
import { ProlongSessionRequest } from 'api/methods/auth/types/prolong-session';

export default class ServiceStore {

    constructor(private api: Api,
        private locStore: LocalizationStore,
        private userStore: UserStore) {
        this.api = api || inject(this, Api);
        this.locStore = locStore || inject(this, LocalizationStore);
        this.userStore = userStore || inject(this, UserStore);
    }

    async runCustomEvent(model: Partial<RunCustomEventRequestModel>) {
        const result = await this.api.service.runCustomEvent(new RunCustomEventRequest(model));
        return result.success;
    }

    async contactInFilter(contactId: string, filterId: string) {
        const result = await this.api.service.contactInFilter(new ContactInFilterRequest(filterId, contactId));
        if (result.response.isInFilter == true) return true;
        return false;
    }

    async updateLocalization(reloadPageIfSuccess: boolean = true) {
        const result = await this.api.service.updateLocalization(new UpdateLocalizationRequest());
        if (result.success) {
            this.locStore.clear();
            if (reloadPageIfSuccess)
                document.location.reload();
        }
        return result;
    }

    async refreshTokens() {
        if (!this.userStore.refreshToken)
            return false;
        const result = await this.api.auth.refreshToken(new RefreshTokenRequest(this.userStore.refreshToken));
        return result.success;
    }

    async updateUser() {
        const result = await this.api.auth.fetchMe(new FetchMeRequest());
        if (result.success)
            this.userStore.updateUser(result.response.student);
        return result.success;
    }

    async keepAlive() {
        if (!this.userStore.refreshToken)
            return false;
        const result = await this.api.service.keepalive(new KeepaliveRequest());
        return result.success;
    }

    async selectLang(lang: Lang): Promise<boolean> {
        const result = await this.api.student.selectLang(new SelectLangRequest(lang));
        return result.success;
    }

    async prolongSession() {
        if (!this.userStore.refreshToken)
            return false;
        const result = await this.api.auth.prolongSession(new ProlongSessionRequest(this.userStore.refreshToken));
        return result.success;
    }
}