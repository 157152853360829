import React from "react";
import { Card } from "antd";
import styles from './GamificationCard.module.scss';
import { combineClasses } from "@axl/accel-framework/utils";
import { ArrowRightOutlined } from "@ant-design/icons";

export type GamificationCardProps = {
    header: React.ReactNode;
    onTitleClick?: () => void;
}

export const GamificationCard: React.FC<GamificationCardProps> = ({ header, children, onTitleClick }) => {
    return <Card className={combineClasses(styles.gamification_card, 'gamification-card')}>
        <div className="gamification-card--header fw-500">
            {header && <a className="color-gray-8" onClick={onTitleClick}>{header}&nbsp;<ArrowRightOutlined /></a>}
        </div>
        {children}
    </Card>;
};
