import { Entity, SchoolContentItemProgressRating } from "@axl/accel-framework/models";
import { action, observable } from "mobx";
import moment from "moment";

export default class SchoolLibraryItemProgress extends Entity {
    constructor(progress?: Partial<SchoolLibraryItemProgress>) {
        super(progress);
        if (progress) this.update(progress);
    }

    @observable beginDate: moment.Moment;
    @observable rating: SchoolContentItemProgressRating;

    static fromJson(json: any): SchoolLibraryItemProgress {
        return new SchoolLibraryItemProgress({
            ...json,
            beginDate: json.createdDate ? moment(json.createdDate) : undefined,
            rating: json.rating ? SchoolContentItemProgressRating.fromJson(json.rating) : null,
        });
    }

    @action update(progress: Partial<SchoolLibraryItemProgress>) {
        super.update(progress);
    }
}
