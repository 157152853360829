import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseProgress } from 'models';
import { CourseItemTaskAnswerStatus } from '@axl/accel-framework/models';

type CourseItemTaskFieldModel = {
    id: string;
    value: any;
}

export type SaveTaskAnswerRequestModel = {
    id: string,
    text: string,
    files: string[],
    fields: CourseItemTaskFieldModel[]
}
export class SaveTaskAnswerRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = `/student-course-item/task-answer`;
    public body = {
        ...this.model
    }

    constructor(public model: SaveTaskAnswerRequestModel) {
        super();
    }
}

export class SaveTaskAnswerResponse extends ApiResponse {
    progress: StudentCourseProgress;
    status: CourseItemTaskAnswerStatus;

    constructor(response: any) {
        super(response);
        this.status = this.body.answerStatus;
        this.progress = StudentCourseProgress.fromJson(this.body.progress);
    }
}