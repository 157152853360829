import Api from 'api';
import { LoginRequest, LoginResponse } from './types/login';
import { RefreshTokenRequest, RefreshTokenResponse } from './types/refresh-token';
import { RegisterRequest, RegisterResponse } from './types/register';
import { ResetPasswordResponse, ResetPasswordRequest } from './types/reset-password';
import { ForgotPasswordRequest, ForgotPasswordResponse } from './types/forgot-password';
import { ConfirmEmailRequest, ConfirmEmailResponse } from './types/confirm-email';
import { ConfirmInviteRequest, ConfirmInviteResponse } from './types/confirm-invite';
import { CheckInviteRequest, CheckInviteResponse } from './types/check-invite';
import { LoginTokenRequest, LoginTokenResponse } from './types/login-token';
import { LogoutRequest, LogoutResponse } from './types/logout';
import { ProlongSessionRequest, ProlongSessionResponse } from './types/prolong-session';
import { FetchMeRequest, FetchMeResponse } from './types/me';
import { LoginAsRequest, LoginAsResponse } from './types/login-as';
import { SendInviteRequest, SendInviteResponse } from './types/send-invite';
import { FetchContactRequest, FetchContactResponse } from './types/contact';
import { RegisterContactRequest, RegisterContactResponse } from './types/register-contact';
import { FetchEmailNotificationsSubscriptionStatusRequest, FetchEmailNotificationsSubscriptionStatusResponse } from './types/email-notifications-subscription-status';
import { UnsubscribeEmailNotificationsRequest, UnsubscribeEmailNotificationsResponse } from './types/unsubscribe-email-notifications';

export default class AuthApi {
    constructor(protected api: Api) {
    }

    public async login(request: LoginRequest) {
        return await this.api.execute(request, LoginResponse);
    }

    public async logout(request: LogoutRequest) {
        return await this.api.execute(request, LogoutResponse);
    }

    public async refreshToken(request: RefreshTokenRequest) {
        return await this.api.execute(request, RefreshTokenResponse);
    }

    public async register(request: RegisterRequest) {
        return await this.api.execute(request, RegisterResponse);
    }

    public async registerContact(request: RegisterContactRequest) {
        return await this.api.execute(request, RegisterContactResponse);
    }

    public async resetPassword(request: ResetPasswordRequest) {
        return await this.api.execute(request, ResetPasswordResponse);
    }

    public async confirmEmail(request: ConfirmEmailRequest) {
        return await this.api.execute(request, ConfirmEmailResponse);
    }

    public async checkInvite(request: CheckInviteRequest) {
        return await this.api.execute(request, CheckInviteResponse);
    }

    public async confirmInvite(request: ConfirmInviteRequest) {
        return await this.api.execute(request, ConfirmInviteResponse);
    }

    public async sendInvite(request: SendInviteRequest) {
        return await this.api.execute(request, SendInviteResponse);
    }

    public async forgotPassword(request: ForgotPasswordRequest) {
        return await this.api.execute(request, ForgotPasswordResponse);
    }

    public async loginToken(request: LoginTokenRequest) {
        return await this.api.execute(request, LoginTokenResponse);
    }

    public async loginAs(request: LoginAsRequest) {
        return await this.api.execute(request, LoginAsResponse);
    }

    public async prolongSession(request: ProlongSessionRequest) {
        return await this.api.execute(request, ProlongSessionResponse);
    }

    public async fetchContact(request: FetchContactRequest) {
        return await this.api.execute(request, FetchContactResponse);
    }

    public async fetchMe(request: FetchMeRequest) {
        return await this.api.execute(request, FetchMeResponse);
    }

    public async fetchEmailNotificationsSubscriptionStatus(request: FetchEmailNotificationsSubscriptionStatusRequest) {
        return await this.api.execute(request, FetchEmailNotificationsSubscriptionStatusResponse);
    }

    public async unsubscribeEmailNotifications(request: UnsubscribeEmailNotificationsRequest) {
        return await this.api.execute(request, UnsubscribeEmailNotificationsResponse);
    }
}