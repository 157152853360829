import { ApiRequest, ApiResponse, ApiResult, HttpApiClient } from '@axl/accel-framework/api';
import { HttpStatusCode } from '@axl/accel-framework/enums';
import { HubClient } from '@axl/accel-framework/hub-client';
import { FetchMeRequest, FetchMeResponse } from 'api/methods/auth/types/me';
import { RefreshTokenRequest, RefreshTokenResponse } from 'api/methods/auth/types/refresh-token';
import { UserStore } from 'stores';

export default class JwtApiClient extends HttpApiClient {
    private readonly MaxAttempts = 3;

    constructor(apiUrl: string,
        private userStore: UserStore,
        private hub: HubClient) {
        super(apiUrl);
    }

    buildHeaders(request: ApiRequest) {
        const headers: any = {
            'Content-Type': 'application/json',
            'X-Timezone-Offset': new Date().getTimezoneOffset()
        };
        if (!request.authorized)
            return headers;
        if (this.userStore.accessToken)
            headers['Authorization'] = `Bearer ${this.userStore.accessToken}`;
        if (this.hub.connectionId)
            headers['ConnectionId'] = this.hub.connectionId;
        return headers;
    }

    public async execute<T extends ApiResponse>(request: ApiRequest, ResponseType: ({ new(json: any): T }) | undefined = undefined, attempt = 1): Promise<ApiResult<T>> {
        let result = await this.processRequest(request, ResponseType);
        if (request.authorized) {
            // 401 - refresh tokens and repeat request
            if (result.status == HttpStatusCode.Unauthorized) {
                if (attempt == this.MaxAttempts || !await this.refreshTokens())
                    return result;
                // repeat prev request
                result = await this.execute(request, ResponseType, attempt + 1);
            } else
                // e.g. access token is expired => refresh tokens
                if (result.response.resetToken)
                    await this.refreshTokens();
        }
        if (result.response.hasTokens) {
            this.userStore.updateTokens(result.response.accessToken, result.response.refreshToken);

            if (request.autoUpdateUser)
                // it is important to refresh the user after every token change
                await this.fetchMe();
        }
        return result;
    }

    private async refreshTokens(): Promise<boolean> {
        const result = await this.execute(new RefreshTokenRequest(this.userStore.refreshToken!), RefreshTokenResponse);
        if (!result.success) {
            this.userStore.reset();
            return false;
        }
        return result.success;
    }

    private async fetchMe(): Promise<boolean> {
        const result = await this.execute(new FetchMeRequest(), FetchMeResponse);
        if (result.success)
            this.userStore.updateUser(result.response.student);
        return result.success;
    }
}
