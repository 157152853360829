import { HubSubscription } from '@axl/accel-framework/hub-client';

export type SchoolSupportChatChangedPayload = {
    enabled: boolean;
}

export default class SchoolSupportChatChangedSubscription extends HubSubscription<SchoolSupportChatChangedPayload> {
    method = 'SchoolSupportChatChanged';
    protected processPayload = (json: any): SchoolSupportChatChangedPayload => {
        return {
            enabled: json.enabled
        };
    }
}