import Api from 'api';
import { FetchForumThreadRequest, FetchForumThreadResponse } from './types/fetch-forum-thread';
import { CreateTopicRequest, CreateTopicResponse } from './types/create-topic';
import { FetchTopicCommentsRequest, FetchTopicCommentsResponse } from './types/fetch-topic-comments';
import { FetchTopicRequest, FetchTopicResponse } from './types/fetch-topic';
import { CreateTopicCommentRequest, CreateTopicCommentResponse } from './types/create-topic-comment';
import { UpdateTopicCommentRequest, UpdateTopicCommentResponse } from './types/update-topic-comment';
import { DeleteTopicCommentRequest, DeleteTopicCommentResponse } from './types/delete-topic-comment';
import { UpdateTopicRequest, UpdateTopicResponse } from './types/update-topic';
import { FetchTopicCommentPositionRequest, FetchTopicCommentPositionResponse } from './types/fetch-topic-comment-position';
import { DeleteTopicRequest, DeleteTopicResponse } from './types/delete-topic';
import { BlockStudentRequest, BlockStudentResponse } from './types/block-student';
import { CreateReactionRequest, CreateReactionResponse } from './types/create-reaction';
import { DeleteReactionRequest, DeleteReactionResponse } from './types/delete-reaction';

export default class ForumApi {
    constructor(private api: Api) {
    }

    public async fetchThread(request: FetchForumThreadRequest) {
        return await this.api.execute(request, FetchForumThreadResponse);
    }

    public async fetchTopic(request: FetchTopicRequest) {
        return await this.api.execute(request, FetchTopicResponse);
    }

    public async createTopic(request: CreateTopicRequest) {
        return await this.api.execute(request, CreateTopicResponse);
    }

    public async updateTopic(request: UpdateTopicRequest) {
        return await this.api.execute(request, UpdateTopicResponse);
    }

    public async fetchTopicComments(request: FetchTopicCommentsRequest) {
        return await this.api.execute(request, FetchTopicCommentsResponse);
    }

    public async createTopicComment(request: CreateTopicCommentRequest) {
        return await this.api.execute(request, CreateTopicCommentResponse);
    }

    public async updateTopicComment(request: UpdateTopicCommentRequest) {
        return await this.api.execute(request, UpdateTopicCommentResponse);
    }

    public async deleteTopicComment(request: DeleteTopicCommentRequest) {
        return await this.api.execute(request, DeleteTopicCommentResponse);
    }

    public async fetchTopicCommentPosition(request: FetchTopicCommentPositionRequest) {
        return await this.api.execute(request, FetchTopicCommentPositionResponse);
    }

    public async deleteTopic(request: DeleteTopicRequest) {
        return await this.api.execute(request, DeleteTopicResponse);
    }

    public async blockStudent(request: BlockStudentRequest) {
        return await this.api.execute(request, BlockStudentResponse);
    }

    public async createReaction(request: CreateReactionRequest) {
        return await this.api.execute(request, CreateReactionResponse);
    }

    public async updateReaction(request: CreateReactionRequest) {
        return await this.api.execute(request, CreateReactionResponse);
    }

    public async deleteReaction(request: DeleteReactionRequest) {
        return await this.api.execute(request, DeleteReactionResponse);
    }
}