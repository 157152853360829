import Api from 'api';
import { ContactInFilterRequest, ContactInFilterResponse } from './types/contact-in-filter';
import { KeepaliveRequest, KeepaliveResponse } from './types/keepalive';
import { RunCustomEventRequest, RunCustomEventResponse } from './types/run-custom-event';
import { TrackFormRequest, TrackFormResponse } from './types/track-form';
import { UpdateLocalizationRequest, UpdateLocalizationResponse } from './types/update-localization';

export default class ServiceApi {
    constructor(private api: Api) {
    }

    public async keepalive(request: KeepaliveRequest) {
        return await this.api.execute(request, KeepaliveResponse);
    }

    public async updateLocalization(request: UpdateLocalizationRequest) {
        return await this.api.execute(request, UpdateLocalizationResponse);
    }

    public async runCustomEvent(request: RunCustomEventRequest) {
        return await this.api.execute(request, RunCustomEventResponse);
    }

    public async contactInFilter(request: ContactInFilterRequest) {
        return await this.api.execute(request, ContactInFilterResponse);
    }

    public async trackForm(request: TrackFormRequest) {
        return await this.api.execute(request, TrackFormResponse);
    }
}