import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class FetchTopicCommentPositionRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/forum/topic/${this.topicId}/comment-position/${this.sequenceNumber}`;

    constructor(public topicId: string, public sequenceNumber: number) {
        super();
    }
}

export class FetchTopicCommentPositionResponse extends ApiResponse {
    public position: number;
    constructor(response: any) {
        super(response);
        this.position = this.body;
    }
}
