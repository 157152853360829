import { BaseFilter } from '@axl/accel-framework/models';
import { StudentCourseItem } from '..';
import { SortingDirection } from '@axl/accel-framework/enums';

export default class StudentCourseItemFilter extends BaseFilter<StudentCourseItem> {
    constructor(filter?: Partial<StudentCourseItemFilter>) {
        super();
        this.sortName = 'position';
        this.sortType = SortingDirection.asc;
        if (filter) this.update(filter);
    }
    studentCourseId: string;
    /**
     * Item we'd like to select
     * In addition to root branch, request the item section branch
     */
    courseItemId: string;
    /**
     * Request only the section items
     */
    sectionId: string;

    update(changes: Partial<StudentCourseItemFilter>) {
        super.update(changes);
    }
}