import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { SchoolLibrary } from 'models';

export class FetchSchoolLibraryRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        title,
        flowBeginDate
    }`;
    public url: string = `/school-library/${this.id}`;

    constructor(public id: string) {
        super();
    }
}

export class FetchSchoolLibraryResponse extends ApiResponse {
    library: SchoolLibrary;
    constructor(response: any) {
        super(response);
        this.library = SchoolLibrary.fromJson(this.body);
    }
}