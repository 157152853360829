import React from "react";
import { Space } from 'antd';
import { Currency } from '@axl/accel-framework/enums';
import { Loc, NumberFormat } from '@axl/accel-framework/components';
import { useSchool } from 'components';

export type PriceProps = {
    price: number;
    label?: React.ReactNode;
    precision?: number;
    colon?: boolean;
    currency?: Currency;
    className?: string;
    priceClassName?: string;
    fractionalClassname?: string;
    truncateIfFractionalZero?: boolean;
}

const Price: React.FC<PriceProps> = ({ price, precision, currency, label, colon, className, priceClassName, fractionalClassname, truncateIfFractionalZero }) => {
    const school = useSchool();

    const c = currency ?? school?.currency!;
    const priceComponent = <NumberFormat number={Math.abs(price)}
        precision={precision}
        truncateIfFractionalZero={truncateIfFractionalZero}
        className={priceClassName}
        fractionalClassname={fractionalClassname} />;

    const isUsd = c == Currency.USD;
    const curLoc = <Loc word={`Currency.${c}`} default={c} />;

    return <Space size={5} className={className}>
        {price < 0 && <span>-</span>}
        {label && <span>
            {label}
            {colon && <>:</>}
        </span>}
        <span>
            {isUsd && curLoc}
            {priceComponent}
        </span>
        {!isUsd && curLoc}
    </Space>;
}
export default Price;