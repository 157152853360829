import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { Lang } from '@axl/accel-framework/enums';

export type RegisterContactRequestModel = {
    contactId: string;
    email: string;
    password: string;
    confirmPassword: string;
    lang: Lang;
    timezoneOffset: string;
    urlParameters: any;
    isAgreementAccepted: boolean;
}

export class RegisterContactRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/register-contact';
    public body: any = {
        ...this.model
    };
    public authorized = false;

    constructor(public model: RegisterContactRequestModel) {
        super();
    }
}

export class RegisterContactResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}