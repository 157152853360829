import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItem } from 'models';

export class FetchCourseItemRequest extends ApiRequest {
    public method = HttpMethod.GET;
   
    public url: string = `/course-preview/${this.courseId}/items/${this.itemId}`;

    constructor(public courseId: string, public itemId: string) {
        super();
    }
}

export class FetchCourseItemResponse extends ApiResponse {
    courseItem: StudentCourseItem;
    constructor(response: any) {
        super(response);
        this.courseItem = StudentCourseItem.fromJson(this.body);
    }
}