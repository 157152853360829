import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type UnsubscribeModel = {
    messageId: string;
    studentId: string;
    timestamp: number;
    hash: string;
}

export class UnsubscribeRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public body = {
        ...this.model
    }
    public url: string = `/student/unsubscribe-email-broadcast`;

    constructor(public model: UnsubscribeModel) {
        super();
    }
}

export class UnsubscribeResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}