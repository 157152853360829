import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export class KeepaliveRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/service/keepalive';
}

export class KeepaliveResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}