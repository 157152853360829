
import { EZEditorViewProps } from "@axl/ez-editor/viewer/react/EZEditorView";
import { useSettings } from "components";
import React, { Suspense, useEffect } from "react";
const EZEditorView = React.lazy(() => import('@axl/ez-editor/viewer/react/EZEditorView'));

type Props = Omit<EZEditorViewProps, 'baseUrl'> & {
    noCopy?: boolean;
}

const SchoolEZEditorView: React.FC<Props> = (props) => {
    const appSettings = useSettings();

    /**
     * player post message
     */
    useEffect(() => {
        const cb = (e: any) => {
            if (e.data.source != 'video') {
                return;
            }
            const axlData = window.AXLData;
            e.source.postMessage({
                source: 'video',
                videoId: e.data.videoId,
                contactId: axlData.contactId,
                courseId: axlData.courseId,
                courseItemId: axlData.courseItemId,
                libraryId: axlData.libraryId,
                libraryItemId: axlData.libraryItemId
            }, "*");
        };
        window.addEventListener("message", cb);
        return () => window.removeEventListener('message', cb);
    }, []);

    return <Suspense fallback={<div>Loading</div>}>
        <EZEditorView
            {...props}
            baseUrl={appSettings.api.baseUrl}
        />
    </Suspense>;
}

export default SchoolEZEditorView;