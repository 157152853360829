import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseProgress } from 'models';

export class ResetTaskAnswerStatusRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = `/student-course-item/task-answer/reset-status`;
    public body = {
        answerId: this.answerId,
    }

    constructor(public answerId: string) {
        super();
    }
}

export class ResetTaskAnswerStatusResponse extends ApiResponse {
    progress: StudentCourseProgress;
    constructor(response: any) {
        super(response);
        this.progress = StudentCourseProgress.fromJson(this.body);
    }
}