import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemProgressRating, SchoolContentSectionType } from '@axl/accel-framework/models';
import { StudentCourseProgress } from 'models';

export type CreateCourseItemRating = {
    sectionType: SchoolContentSectionType;
    targetItemId: string;
    targetProgressId: string;
    text: string;
    fileIds: string[];
    rating?: number;
}
export class CreateCourseItemRatingRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/school-content-item-rating';
    public body: CreateCourseItemRating = {
        sectionType: this.sectionType,
        targetItemId: this.targetItemId,
        targetProgressId: this.targerProgressId,
        fileIds: this.rating.files?.map(x => x.id) ?? [],
        text: this.rating.comment,
        rating: this.rating.rating,
    };

    constructor(
        public rating: SchoolContentItemProgressRating, 
        public sectionType: SchoolContentSectionType, 
        public targetItemId: string, 
        public targerProgressId: string) {
        super();
    }
}

export class CreateCourseItemRatingResponse extends ApiResponse {
    public id: string;
    public progress: StudentCourseProgress;
    constructor(response: any) {
        super(response);
        this.id = this.body.ratingId;
        this.progress = StudentCourseProgress.fromJson(this.body.progress);
    }
}