import { Announcement } from '@axl/accel-framework/models';
import Api from 'api';

export default class AnnouncementApi {
    constructor(private api: Api) {
    }

    fetch = async () => {
        return await this.api.get<Announcement[]>(`/announcement`,
            '',
            json => json.map((item: any) => Announcement.fromJson(item)));
    }
    close = async (id: string) => {
        return await this.api.post(`/announcement/close`, { id });
    }
}