import Api from 'api';
import { FetchSchoolLibraryItemsRequest, FetchSchoolLibraryItemsResponse } from './types/fetch-school-library-items';
import { FetchSchoolLibraryItemRequest, FetchSchoolLibraryItemResponse } from './types/fetch-school-library-item';
import { FetchSchoolLibraryRequest, FetchSchoolLibraryResponse } from './types/fetch-school-library';

export default class SchoolLibraryApi {
    constructor(private api: Api) {
    }

    public async fetchLibrary(request: FetchSchoolLibraryRequest) {
        return await this.api.execute(request, FetchSchoolLibraryResponse);
    }

    public async fetchLibraryItem(request: FetchSchoolLibraryItemRequest) {
        return await this.api.execute(request, FetchSchoolLibraryItemResponse);
    }

    public async fetchLibraryItems(request: FetchSchoolLibraryItemsRequest) {
        return await this.api.execute(request, FetchSchoolLibraryItemsResponse);
    }
}