import { ApiRequest, ApiResponse, HttpMethod, Optional, toOptional } from '@axl/accel-framework/api';
import { UserNotificationSettings } from '@axl/accel-framework/models';

export type SaveNotificationSettingsModel = {
    useEmail: Optional<boolean>;
    customEmail: Optional<string>;
    useTg: Optional<boolean>;
}

export class SaveNotificationSettingsRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public body: SaveNotificationSettingsModel = {
        useEmail: toOptional(this.settings.useEmail),
        customEmail: toOptional(this.settings.customEmail ?? undefined),
        useTg: toOptional(this.settings.useTg),
    }
    public url: string = `/notification/settings`;

    constructor(public settings: UserNotificationSettings) {
        super();
    }
}

export class SaveNotificationSettingsResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}