import { LoadingOverlay } from '@axl/accel-framework/components';
import { useLoc } from 'components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import Favicon from 'react-favicon';
import MetaTags from 'react-meta-tags';
import { SchoolStore } from 'stores';
import { FileProvider } from '@axl/accel-framework/utils';
import { notification } from 'antd';
import { HubClient } from '@axl/accel-framework/hub-client';
import SchoolSupportChatChangedSubscription from 'hub-client/subscriptions/SchoolSupportChatChangedSubscription';

type Props = {
}
const SchoolProvider: React.FC<Props> = ({ children }) => {
    const schoolStore = useInstance(SchoolStore);
    const loc = useLoc();
    const fileProvider = useInstance(FileProvider);
    const hub = useInstance(HubClient);

    const [ready, setReady] = useState(schoolStore.ready);

    useEffect(() => {
        if (ready) return;
        (async () => {
            await schoolStore.fetchSchool();
            if (schoolStore.ready) {
                setReady(true);
                return;
            }
            notification.error({ message: loc.word('Error occured'), duration: 2 });
        })();
    }, []);

    useEffect(() => {
        const sub = hub.on(new SchoolSupportChatChangedSubscription(), (payload) => {
            schoolStore.school.update({ supportChatEnabled: payload.enabled });
        });
        return () => sub.dispose();
    }, []);

    if (!ready) return <LoadingOverlay position='absolute' />;
    return <>
        <MetaTags>
            <meta name="description" content={schoolStore.school.description} />
            <meta property="og:title" content={schoolStore.school.name} />
            {schoolStore.school.logo && <meta property="og:image" content={fileProvider.getUrl(schoolStore.school.logo)} />}
        </MetaTags>
        {schoolStore.school.favicon
            ? <Favicon url={fileProvider.getUrl(schoolStore.school.favicon)!} />
            : <Favicon url="/favicon.png" />}
        {children}
    </>;
}

export default observer(SchoolProvider);