import { executeJs, isEmpty } from "@axl/accel-framework/utils";
import { useEffect } from "react";

type ScriptInjectionProps = {
    script: string;
    target: 'head' | 'body';
}
const ScriptInjection: React.FC<ScriptInjectionProps> = ({ script, target }) => {
    useEffect(() => {
        if (isEmpty(script)) return;
        executeJs(script, target);
    }, []);
    return null;
}
export default ScriptInjection;