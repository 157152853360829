import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import type { ForumReactionType } from '@axl/accel-framework/models/forum/reaction/ForumReactionType';
import { ForumReaction } from '@axl/accel-framework/models';

export type CreateReactionRequestModel = {
    topicId?: string;
    commentId?: string;
    type: ForumReactionType;
}
export class CreateReactionRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/forum/reaction';
    public body = {
        ...this.model
    }

    constructor(public model: CreateReactionRequestModel) {
        super();
    }
}

export class CreateReactionResponse extends ApiResponse {
    reaction: ForumReaction;
    constructor(response: any) {
        super(response);
        this.reaction = ForumReaction.fromJson(response.body);
    }
}