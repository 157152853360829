import { Entity } from '@axl/accel-framework/models';
import { isEmpty } from 'lodash';
import { observable, action, computed } from 'mobx';

export default class StudentSession extends Entity {
    constructor(session?: Partial<StudentSession>) {
        super(session);
        if (session) this.update(session);
    }

    @observable adminId: string;
    @observable expiredDate?: Date;

    static fromJson(json: any): StudentSession {
        return new StudentSession({
            ...json,
            expiredDate: json.expiredDate ? new Date(json.expiredDate) : undefined
        });
    }

    @action update(item: Partial<StudentSession>) {
        super.update(item);
    }

    @computed get isAdmin() {
        return !isEmpty(this.adminId);
    }
}