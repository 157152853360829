import Api from 'api';
import { CreateNotificationChannelRequest, CreateNotificationChannelResponse } from './types/create';
import { SaveNotificationChannelRequest, SaveNotificationChannelResponse,  } from './types/save';

export default class NotificationChannelApi {
    constructor(private api: Api) {
    }

    public async create(request: CreateNotificationChannelRequest) {
        return await this.api.execute(request, CreateNotificationChannelResponse);
    }

    public async save(request: SaveNotificationChannelRequest) {
        return await this.api.execute(request, SaveNotificationChannelResponse);
    }
}