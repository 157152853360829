import React, { useState } from 'react';
import { Button, Checkbox, Drawer, Table, Typography } from 'antd';
import { useInstance } from 'react-ioc';
import { PlusCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import Api from 'api';
import { LocalizationLanguage, LocalizationTranslation } from '@axl/accel-framework/models';
import { useLoc } from 'components';

type MissingWord = any & {
    added?: boolean;
}

type MissingWordsProps = {
    words?: Array<MissingWord>;
    languages: LocalizationLanguage[];
    project?: any,
    onCancel: () => void;
}

type MissingWordsState = {
    words: Array<MissingWord>;
    fetching: boolean;
}

type NewWordState = {
    word?: MissingWord;
    useInAdmin?: boolean;
    useInSchool?: boolean;
    useInManager?: boolean;
    useInSite?: boolean;
    drawerVisible: boolean;
}

const MissingWords: React.FC<MissingWordsProps> = ({ words, languages, project, onCancel }) => {
    const api = useInstance(Api);
    const loc = useLoc();

    const [newWordState, setNewWordState] = useState<NewWordState>({
        drawerVisible: false
    });

    const [state, setState] = useState<MissingWordsState>({
        words: words ?? [],
        fetching: false
    });
    const [filter, setFilter] = useState<any>({
        excludeTemplateStrings: false,
        project: project,
    });

    const openWord = async (word: MissingWord) => {
        setNewWordState({
            drawerVisible: true,
            word,
            useInAdmin: project == 'admin',
            useInSchool: project == 'school',
            useInManager: project == 'manager',
            useInSite: project == 'site' || project == 'webinar',
        });
    }


    return <>
        <Typography.Title level={2}>{project}'s missing words</Typography.Title>

        <Checkbox checked={filter.excludeTemplateStrings}
            onChange={() => {
                setFilter({
                    ...filter,
                    excludeTemplateStrings: !filter.excludeTemplateStrings
                });
            }}
            className='mb-10'>
            Exclude template strings
        </Checkbox>

        <Table
            dataSource={state.words}
            loading={state.fetching}
            bordered
            columns={[
                {
                    key: 'key',
                    title: 'Key',
                    width: '30%',
                    render: (x, item) => {
                        return <Typography.Text copyable={{
                            text: item.key
                        }}>
                            <b>{item.key}</b>
                            {item.added == true && <CheckOutlined className='ml-5' />}
                        </Typography.Text>;
                    }
                },
                {
                    key: 'value',
                    title: 'Match',
                    width: '50%',
                    render: (x, item) => {
                        return <div style={{ maxWidth: 570 }}>
                            <div>{item.value}</div>
                            <small><i>{item.filePath}</i></small>
                        </div>
                    }
                },
                {
                    key: 'actions',
                    title: 'Actions',
                    width: '20%',
                    render: (x, item) => {
                        if (item.added) {
                            return <Button onClick={() => openWord(item)}
                                size='small'
                                icon={<EditOutlined />}>Edit word</Button>
                        }
                        return <Button onClick={() => openWord(item)}
                            size='small'
                            icon={<PlusCircleOutlined />}>Add word</Button>
                    }
                }
            ]} />

        <footer>
            <Button type='default' onClick={onCancel}>Cancel</Button>
        </footer>
    </>;
}
export default MissingWords;