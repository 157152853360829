import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export class RemoveCourseItemCommentRequest extends ApiRequest {
    public method = HttpMethod.DELETE;
    public url: string = `/school-content-item-comment/${this.commentId}`;

    constructor(public commentId: string) {
        super();
    }
}

export class RemoveCourseItemCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}