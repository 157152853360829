import { action, observable } from 'mobx';
import { AccelFile, Entity } from '@axl/accel-framework/models';
import { StudentCourseItem } from 'models';


export declare type CourseItemTestCountingMethod = 'percent' | 'score';

export default class StudentCourseItemTest extends Entity {
    constructor(test?: Partial<StudentCourseItemTest>) {
        super(test);
        if (test) this.update(test);
    }

    @observable title: string;
    @observable description: string;
    @observable position: number;
    @observable minPercentageCompletion: number;
    @observable minScoreCompletion: number;
    @observable questions: any[];
    @observable files: AccelFile[];
    @observable countingMethod: CourseItemTestCountingMethod;
    @observable shuffle: boolean;
    @observable isCompleted: boolean;
    @observable parent: StudentCourseItem;

    @action
    update(test: Partial<StudentCourseItemTest>) {
        super.update(test);
    }

    static fromJson(json: any, parent?: StudentCourseItem): StudentCourseItemTest {
        return new StudentCourseItemTest({
            ...json,
            files: json.files ? json.files.map(AccelFile.fromJson) : undefined,
            parent
        });
    }
}