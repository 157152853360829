import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export class DeleteNotificationRequest extends ApiRequest {
    public method = HttpMethod.DELETE;
    public url: string = `/notification/${this.id}`;

    constructor(public id: string) {
        super();
    }
}

export class DeleteNotificationResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}