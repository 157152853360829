import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class SendInviteRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/send-invite';
    public body: any = {
        email: this.email
    };

    constructor(public email: string) {
        super();
    }
}

export class SendInviteResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}