import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type UpdateTopicCommentRequestModel = {
    commentId: string,
    text: string,
    files: string[]
    replyId?: string;
}
export class UpdateTopicCommentRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/forum/topic/comment';
    public body = {
        ...this.model
    }

    constructor(public model: UpdateTopicCommentRequestModel) {
        super();
    }
}

export class UpdateTopicCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}