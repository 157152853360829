import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class LoginAsModel {
    sessionId: string;
    studentId: string;
    timestamp: number;
    signature: string;
}

export class LoginAsRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/login-as';
    public body: any = {
        ...this.model
    };

    constructor(public model: Partial<LoginAsModel>) {
        super();
    }
}

export class LoginAsResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}