import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourse } from 'models';

export class FetchStudentCourseRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
            id,
            beginDate,
            endDate,
            state,
            flowBeginDate,
            accessBeginDate,
            accessEndDate,
            isSubscription,
            course{
                id,
                name,
                dontAskFeedback
            },
            currentCourseItem{
                id
            },        
            feedback,
            progress,
            student{
                id
            }
        }`;
    public url: string = `/student-course/${this.courseItemId}`;

    constructor(public courseItemId: string, fields?: string) {
        super();
        this.fields = fields || this.fields;
    }
}

export class FetchStudentCourseResponse extends ApiResponse {
    public studentCourse: StudentCourse;
    public hasItemAccess: boolean;
    constructor(response: any) {
        super(response);
        this.studentCourse = StudentCourse.fromJson(this.body.studentCourse);
        this.hasItemAccess = this.body.hasItemAccess;
    }
}