import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItem } from 'models';

export class FetchStudentCourseItemRequest extends ApiRequest {
    public method = HttpMethod.GET;
  
    public url: string = `/student-course-item/${this.studentCourseId}/${this.courseItemId}`;

    constructor(public studentCourseId: string, public courseItemId: string) {
        super();
    }
}

export class FetchStudentCourseItemResponse extends ApiResponse {
    public studentCourseItem: StudentCourseItem;
    constructor(response: any) {
        super(response);
        this.studentCourseItem = StudentCourseItem.fromJson(this.body);
    }
}
