import Api from 'api';
import { BaseFilter, PartnershipMember, PartnershipOffer, PartnershipTransaction, PartnershipTransactionFilter, PartnershipTransactionRequest, PurchaseOrder } from '@axl/accel-framework/models';
import { CrudApi } from '@axl/accel-framework/api';
import { UtmTag } from 'components/selects/UtmTagSelect/UtmTagSelect';

export type TotalItem = {
    value: number;
    type: string;
    valueType: 'value' | 'currency' | 'percent'
}

export default class PartnershipApi {
    constructor(private api: Api) {
    }

    member = new MemberApi(this.api);
    transaction = new TransactionApi(this.api);
    transactionRequest = new TransactionRequestApi(this.api);
    offer = new OfferApi(this.api);
    order = new PartnershipOrderApi(this.api);
    utm = new UtmTagApi(this.api);

    fetchTotals = (filter: PartnershipTotalsFilter) => this.api.post<{ items: TotalItem[] }>('/partnership/totals', { filter }, json => ({ items: json.items }))
    fetchMaxAmount = () => this.api.get<number>('/partnership/max-amount');
}

export class PartnershipTotalsFilter extends BaseFilter<any> {
    constructor(filter?: Partial<PartnershipTotalsFilter>) {
        super();
        if (filter) this.update(filter);
    }

    dateFrom: moment.Moment | null;
    dateTo: moment.Moment | null;

    utmSources: string[] = [];
    utmMediums: string[] = [];
    utmCampaigns: string[] = [];
    utmTerms: string[] = [];
    utmContents: string[] = [];

    get hasChanges() {
        return this.dateFrom != null
            || this.dateTo != null
            || this.utmSources.length > 0
            || this.utmMediums.length > 0
            || this.utmCampaigns.length > 0
            || this.utmTerms.length > 0
            || this.utmContents.length > 0;
    }

    update(changes: Partial<PartnershipTotalsFilter>) {
        super.update(changes);
    }
}


export type PartnershipTransactionTotals = {
    depositAmount: number;
    pendingAmount: number;
    withdrawalAmount: number;
    requestedAmount: number;
}
export class TransactionApi extends CrudApi<PartnershipTransaction> {
    constructor(private api: Api) {
        super(api, {
            endpoint: '/partnership/transaction',
            parse: e => PartnershipTransaction.fromJson(e),
            defaultFields: `{
                id,
                createdDate,
                amount,
                fee,
                comment,
                partner{
                    id,
                    firstName,
                    lastName,
                    email
                },
                student{
                    id,
                    firstName,
                    lastName,
                    email
                },
                type,
                order{
                    id,
                    number
                },
                validAfterDate,
                level,
                levelPartner{
                    id,
                    firstName,
                    lastName,
                    email
                }
            }`
        });
    }

    fetchTotals = (filter?: PartnershipTransactionFilter) => this.api.post<{ items: TotalItem[] }>(this.settings.endpoint + '/totals', { filter }, json => ({ items: json.items }));
}


export class MemberApi extends CrudApi<PartnershipMember> {
    constructor(private api: Api) {
        super(api, {
            endpoint: '/partnership/member',
            parse: e => PartnershipMember.fromJson(e),
            defaultFields: `{
                id,
                acceptedDate
            }`
        });
    }
    accept = () => this.api.put(this.settings.endpoint, { accepted: true });
}

export class OfferApi extends CrudApi<PartnershipOffer> {
    constructor(api: Api) {
        super(api, {
            endpoint: '/partnership/offer',
            parse: e => PartnershipOffer.fromJson(e),
            defaultFields: `{
                id,
                title,
                url,
                description,
                fee,
                feeType,
                productType,
                levels,
                productLinks{
                    product{
                        id,
                        name,
                    }
                }
            }`
        });
    }
}

export class PartnershipOrderApi extends CrudApi<PurchaseOrder> {
    constructor(api: Api) {
        super(api, {
            endpoint: '/partnership/order',
            parse: e => PurchaseOrder.fromJson(e),
        });
    }
}

export class TransactionRequestApi extends CrudApi<PartnershipTransactionRequest> {
    constructor(api: Api) {
        super(api, {
            endpoint: '/partnership/transaction/request',
            parse: e => PartnershipTransactionRequest.fromJson(e),
            defaultFields: `{
                id,
                amount,
                partnerComment,
                adminComment,
                status,
                createdDate,
                partner{
                    id,
                    firstName,
                    lastName,
                    email
                },
            }`
        });
    }
}

//@ts-ignore
export class UtmTagApi extends CrudApi<UtmTag> {
    constructor(api: Api) {
        super(api, {
            endpoint: '/partnership/utm',
            parse: e => e,
        });
    }
}