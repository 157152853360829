import { AccelFile, CourseItemTask, CourseItemTaskAnswer, CourseItemTaskAnswerStatus, CourseItemTaskField } from '@axl/accel-framework/models';
import { observable, computed, action } from 'mobx';
import { StudentCourseItem } from 'models';

export default class StudentCourseItemTask extends CourseItemTask {
    constructor(item?: Partial<StudentCourseItemTask>) {
        super(item);
        if (item) this.update(item);
    }

    @observable answer: CourseItemTaskAnswer;
    @observable parent: StudentCourseItem;

    @computed get status(): CourseItemTaskAnswerStatus {
        return this.answer?.status || CourseItemTaskAnswerStatus.Default;
    }

    @computed get isCompleted() {
        return this.status === CourseItemTaskAnswerStatus.Correct;
    }

    static fromJson(json: any, parent?: StudentCourseItem): StudentCourseItemTask {
        return new StudentCourseItemTask({
            ...json,
            files: json.files ? json.files.map(AccelFile.fromJson) : undefined,
            answer: json.answer ? CourseItemTaskAnswer.fromJson(json.answer) : undefined,
            fields: json.fields ? json.fields.map(CourseItemTaskField.fromJson) : undefined,
            parent
        });
    }

    @action
    update(task: Partial<StudentCourseItemTask>) {
        super.update(task);
    }
}
