import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ForgotPasswordRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/forgot-password';
    public body: any = {
        email: this.email
    };
    public authorized = false;

    constructor(public email: string) {
        super();
    }
}

export class ForgotPasswordResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}