import { LoadingOverlay, useTimer } from '@axl/accel-framework/components';
import { TimeSpan } from '@axl/accel-framework/utils';
import { useSettings } from 'components';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { ServiceStore, UserStore } from 'stores';

type Props = {
}
const UserProvider: React.FC<Props> = ({ children }) => {
    const userStore = useInstance(UserStore);
    const serviceStore = useInstance(ServiceStore);
    const settings = useSettings();

    const updateTokenTimer = useTimer();

    const [ready, setReady] = useState(userStore.isAuthorized);

    useEffect(() => {
        if (ready) return;
        (async () => {
            // user initialization
            if (!userStore.isAuthenticated)
                // refresh triggers fetchMe by default
                await serviceStore.refreshTokens();
            else
                await serviceStore.updateUser();
            setReady(true);
        })();
    }, []);

    useEffect(() => {
        if (userStore.isAuthorized)
            updateTokenTimer.setInterval(() => serviceStore.refreshTokens(), TimeSpan.fromMinutes(settings.tokenUpdateIntervalMin));
        return () => updateTokenTimer.clear();
    }, [userStore.isAuthorized]);

    if (!ready) return <LoadingOverlay position='absolute' />;
    return <>{children}</>;
}

export default observer(UserProvider);