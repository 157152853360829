import { observable, action, computed } from 'mobx';
import { LibraryItem } from '@axl/accel-framework/models';
import moment from 'moment';
import { LibraryItemType } from '@axl/accel-framework/models/library/item/library-item';
import { isNil } from 'lodash';
import { TimeSpan, isEmpty } from '@axl/accel-framework/utils';
import SchoolLibraryItemProgress from '../school-library-item-progress/school-library-item-progress';

export declare type LibraryItemTab = 'article' | 'rating';

export type SchoolLibraryItemAvailability = {
    result: boolean,
    isSection?: boolean,
    willBeAvailableOn?: moment.Moment,
}

export default class SchoolLibraryItem extends LibraryItem {
    constructor(item?: Partial<SchoolLibraryItem>) {
        super(item);
        if (item) this.update(item);
    }

    @observable parentItem: SchoolLibraryItem | null;
    @observable children: SchoolLibraryItem[] = [];
    @observable section: SchoolLibraryItem | null;
    @observable previousItem: SchoolLibraryItem | null;
    @observable nextItem: SchoolLibraryItem | null;
    @observable studentHasAccess: boolean;
    @observable flowBeginDate?: Date | null;
    @observable progress: SchoolLibraryItemProgress;

    @computed get ratingVisible(): boolean {
        return this.allowComments || this.ratingEnabled;
    }

    @observable private _tab: LibraryItemTab;
    @computed get tab() { return this._tab; }
    set tab(value: LibraryItemTab) {
        switch (value) {
            case 'article': {
                this._tab = 'article';
                return;
            }
            case 'rating': {
                if (this.allowComments || this.ratingEnabled) {
                    this._tab = 'rating';
                    return;
                }
                break;
            }
        }
    }

    @computed get completed() {
        return !isEmpty(this.progress);
    }

    @computed get fullPosition() {
        let position = '';
        let section = this.section;
        while (section != null) {
            position += `${section.position}.`;
            section = section.section;
        }
        position += this.position;
        return position;
    }

    @computed get lastChild() {
        return this.getLastNestedStep();
    }

    getLastNestedStep(): SchoolLibraryItem | null {
        if (this.type == LibraryItemType.Article || this.children.length == 0)
            return null;

        for (const child of this.children.slice().reverse()) {
            if (child.type == LibraryItemType.Article)
                return child;

            const step = child.getLastNestedStep();
            if (step != null)
                return step;
        }
        return null;
    }

    @computed get firstChild() {
        return this.getFirstNestedStep();
    }

    getFirstNestedStep(): SchoolLibraryItem | null {
        if (this.type == LibraryItemType.Article || this.children.length == 0)
            return null;

        const steps = this.children.filter(i => i.type == LibraryItemType.Article);
        if (steps.length > 0)
            return steps[0];

        for (const child of this.children) {
            const step = child.getFirstNestedStep();
            if (step != null)
                return step;
        }
        return null;
    }

    @computed private get parentAvailability(): SchoolLibraryItemAvailability {
        if (this.section != null)
            return this.section.availability;
        return { result: true };
    }

    @computed get canSelect() { return this.availability.result; }
    @computed get availability(): SchoolLibraryItemAvailability {
        const parentAvailability = this.parentAvailability;
        if (!parentAvailability.result)
            return { ...parentAvailability, isSection: true };
        if (!this.availabilityRelativeToTimeDelay.result)
            return this.availabilityRelativeToTimeDelay;
        if (!this.studentHasAccess)
            return { result: false };
        return { result: true };
    }

    @computed private get availabilityRelativeToTimeDelay(): SchoolLibraryItemAvailability {
        if (this.flowTimeDelayActive) {
            switch (this.flowTimeDelayCondition) {
                case 'flowStart': {
                    if (this.flowBeginDate == null)
                        break;
                    const now = moment();
                    const delay = moment(this.flowBeginDate)
                        .add(this.flowTimeDelayValue.milliseconds, 'milliseconds');
                    if (now <= delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
            }
        }
        return { result: true };
    }

    static fromJson(json: any): SchoolLibraryItem {
        const base = LibraryItem.fromJson(json);
        const item = new SchoolLibraryItem({
            ...base,
            studentHasAccess: json.studentHasAccess,
            parentItem: json.parentItem ? SchoolLibraryItem.fromJson(json.parentItem) : null,
            progress: json.progress ? SchoolLibraryItemProgress.fromJson(json.progress) : undefined,
        });
        return item;
    }

    @action
    update(schoolLibraryItem: Partial<SchoolLibraryItem>) {
        super.update(schoolLibraryItem);
    }

    @computed private get flowTimeDelayValue() {
        if (isNil(this.flowTimeDelay))
            return TimeSpan.zero;

        return TimeSpan.fromTimeMeasure(this.flowTimeDelay, this.flowTimeDelayMeasureType ?? 'days');
    }
}

