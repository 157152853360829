import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export type RunCustomEventRequestModel = {
    courseItemId: string,
    sitePageId: string,
    widgetId: string,
    eventName: string
}

export class RunCustomEventRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/service/run-custom-event';
    
    public body = {
        ...this.model
    }


    constructor(public model: Partial<RunCustomEventRequestModel>) {
        super();
    }
}

export class RunCustomEventResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}