import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemProgressRating, SchoolContentItemProgressRatingFilter } from '@axl/accel-framework/models';

export class FetchCourseItemRatingRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/school-content-item-rating';
    public body = {
        ...this.filter,
    }

    constructor(public filter: SchoolContentItemProgressRatingFilter, fields?: string) {
        super();
        this.fields = fields ?? `{
            id,
            comment,
            rating,
            createdDate,
            updatedDate,
            student{
                id,
                email,
                firstName,
                lastName
            },
            comments(filter:"{take:5}"){
                id,
                text,
                createdDate,
                student{
                    id,
                    email,
                    firstName,
                    lastName,
                    avatar {
                        id,
                        cloudKey
                    },
                },
                admin{
                    id,
                    email,
                    firstName,
                    lastName,
                    avatar {
                        id,
                        cloudKey
                    },
                },
                files{
                    id,
                    name,
                    cloudKey,
                    extension,
                    size,
                    type
                },
                _count
            },
            courseItem{
                id,
                title,
                position,
                parentItem{
                    id,
                    title,
                    position
                },
                course{
                    id,
                    name
                }
            }
        }`;
    }
}

export class FetchCourseItemRatingsResponse extends ApiResponse {
    public filter: SchoolContentItemProgressRatingFilter;
    public items: SchoolContentItemProgressRating[];
    constructor(response: any) {
        super(response);
        this.filter = new SchoolContentItemProgressRatingFilter(this.body.filter);
        this.items = this.body.items.map(SchoolContentItemProgressRating.fromJson);
    }
}