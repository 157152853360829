import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ConfirmInviteModel {
    token: string
    password: string;
    confirmPassword: string;
}

export class ConfirmInviteRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/confirm-invite';
    public body: any = {
        ...this.model
    };

    constructor(public model: ConfirmInviteModel) {
        super();
    }
}

export class ConfirmInviteResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}