import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

type ConfirmEmailModel = {
    token: string,
    userId: string,
}

export class ConfirmEmailRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/confirm-email';
    public body: any = {
        ...this.model
    };

    constructor(public model: ConfirmEmailModel) {
        super();
    }
}

export class ConfirmEmailResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}