import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class LoginTokenRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/login-token';
    public body = {
        token: this.token,
    }

    constructor(public token: string) {
        super();
    }
}

export class LoginTokenResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}
