import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemComment, SchoolContentItemCommentFilter } from '@axl/accel-framework/models';

export class FetchCourseItemCommentsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/school-content-item-comment/${this.progressId}`;
    public body = {
        ...this.filter,
    }

    constructor(public progressId: string, public filter: SchoolContentItemCommentFilter) {
        super();
    }
}

export class FetchCourseItemCommentsResponse extends ApiResponse {
    public filter: SchoolContentItemCommentFilter;
    public items: SchoolContentItemComment[];
    constructor(response: any) {
        super(response);
        this.filter = new SchoolContentItemCommentFilter(this.body.filter);
        this.items = this.body.items.map(SchoolContentItemComment.fromJson);
    }
}