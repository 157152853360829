import Api from 'api';
import { FetchStudentCourseRequest, FetchStudentCourseResponse } from './types/fetch-student-course';
import { FetchStudentCourseItemRequest, FetchStudentCourseItemResponse } from './types/fetch-student-course-item';
import { FetchStudentCourseItemsRequest, FetchStudentCourseItemsResponse } from './types/fetch-student-course-items';
import { CompleteStudentCourseItemRequest, CompleteStudentCourseItemResponse } from './types/complete-student-course-item';
import { ResetStudentCourseItemRequest, ResetStudentCourseItemResponse } from './types/reset-student-course-item';
import { UnblockStudentCourseItemRequest, UnblockStudentCourseItemResponse } from './types/unblock-student-course-item';
import { BlockStudentCourseItemRequest, BlockStudentCourseItemResponse } from './types/block-student-course-item';


export default class StudentCoursePreviewApi {
    constructor(private api: Api) {
    }

    public async fetchStudentCourse(request: FetchStudentCourseRequest) {
        return await this.api.execute(request, FetchStudentCourseResponse);
    }

    public async fetchStudentCourseItem(request: FetchStudentCourseItemRequest) {
        return await this.api.execute(request, FetchStudentCourseItemResponse);
    }

    public async fetchStudentCourseItems(request: FetchStudentCourseItemsRequest) {
        return await this.api.execute(request, FetchStudentCourseItemsResponse);
    }

    public async completeStudentCourseItem(request: CompleteStudentCourseItemRequest) {
        return await this.api.execute(request, CompleteStudentCourseItemResponse);
    }

    public async resetStudentCourseItem(request: ResetStudentCourseItemRequest) {
        return await this.api.execute(request, ResetStudentCourseItemResponse);
    }

    public async unblockStudentCourseItem(request: UnblockStudentCourseItemRequest) {
        return await this.api.execute(request, UnblockStudentCourseItemResponse);
    }

    public async blockStudentCourseItem(request: BlockStudentCourseItemRequest) {
        return await this.api.execute(request, BlockStudentCourseItemResponse);
    }
}