import Api from 'api';
import { FetchNotificationSettingsRequest, FetchNotificationSettingsResponse } from './types/fetch';
import { SaveNotificationSettingsRequest, SaveNotificationSettingsResponse } from './types/save';

export default class NotificationSettingsApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchNotificationSettingsRequest) {
        return await this.api.execute(request, FetchNotificationSettingsResponse);
    }

    public async save(request: SaveNotificationSettingsRequest) {
        return await this.api.execute(request, SaveNotificationSettingsResponse);
    }
}