import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type SaveTaskAnswerCommentRequestModel = {
    commentId: string,
    comment: string,
    files: string[]
}
export class SaveTaskAnswerCommentRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = `/student-course-item/task-answer/comment`;
    public body = {
        ...this.model
    }

    constructor(public model: SaveTaskAnswerCommentRequestModel) {
        super();
    }
}

export class SaveTaskAnswerCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}