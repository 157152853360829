import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { UserNotificationSettings } from '@axl/accel-framework/models';

export class FetchNotificationSettingsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        useEmail,
        email,
        customEmail,
        useTg,
        tgId,
        tgLink,
        channels {
            id,
            useSite,
            useEmail,
            useTg,
            notificationType,
            subscriptionId
        }
    }`;
    public url: string = `/notification/settings`;

    constructor(fields?: string) {
        super();
        this.fields = fields || this.fields;
    }
}

export class FetchNotificationSettingsResponse extends ApiResponse {
    public settings: UserNotificationSettings;
    constructor(response: any) {
        super(response);
        this.settings = UserNotificationSettings.fromJson(this.body);
    }
}