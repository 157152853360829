import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { Course } from '@axl/accel-framework/models';

export class FetchCourseRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        name
    }`;
    public url: string = `/course-preview/${this.id}`;

    constructor(public id: string) {
        super();
    }
}

export class FetchCourseResponse extends ApiResponse {
    course: Course;
    constructor(response: any) {
        super(response);
        this.course = Course.fromJson(this.body);
    }
}