import { observable, action, computed } from 'mobx';
import { ContactCert, Entity } from '@axl/accel-framework/models';
import { StudentCourse } from 'models';

export default class SchoolCourse extends Entity {
    constructor(course?: Partial<SchoolCourse>) {
        super(course);
        if (course) this.update(course);
    }

    @observable title: string;
    @observable shortDescription: string;
    @observable freeAccess: boolean;
    @observable isCompleteAnyCourseRequirement: boolean;
    @observable primaryImageCloudKey: string;
    @observable studentCourse?: StudentCourse;
    @observable studentAccessDate?: Date;
    @observable studentHasAccess: boolean;
    @observable categories: string[];
    @observable requirements: string[];
    @observable cert: ContactCert | null;

    @computed get hasRequirements() {
        return this.requirements && this.requirements.length > 0;
    }

    @computed get hasCourseItem() {
        return this.studentCourse && this.studentCourse.currentCourseItem;
    }

    static fromJson(json: any): SchoolCourse {
        return new SchoolCourse({
            ...json,
            studentAccessDate: json.studentAccessDate ? new Date(json.studentAccessDate) : undefined,
            studentCourse: json.studentCourse ? StudentCourse.fromJson(json.studentCourse) : undefined,
            cert: json.cert ? ContactCert.fromJson(json.cert) : undefined,
        });
    }

    @action
    update(course: Partial<SchoolCourse>, allowUndefined = false) {
        super.update(course, allowUndefined);
    }
}

