import { ContactCourse } from '@axl/accel-framework/models';
import { StudentCourseItemProgress } from 'models';

export default class StudentCourseProgress {
    constructor(progress?: Partial<StudentCourseProgress>) {
        if (progress) Object.assign(this, progress);
    }

    courseItemId: string;
    contactCourse: ContactCourse;
    courseItemProgress: StudentCourseItemProgress;

    static fromJson(json: any): StudentCourseProgress {
        return new StudentCourseProgress({
            courseItemId: json.courseItemId,
            contactCourse: json.studentCourse ? ContactCourse.fromJson(json.studentCourse) : undefined,
            courseItemProgress: json.courseItemProgress ? StudentCourseItemProgress.fromJson(json.courseItemProgress) : undefined,
        });
    }
}

