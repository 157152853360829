import Api from 'api';
import { FetchCourseItemRatingRequest, FetchCourseItemRatingsResponse } from './types/fetch-course-item-ratings';
import { SaveCourseItemRatingRequest, SaveCourseItemRatingResponse } from './types/save-course-item-rating';
import { CreateCourseItemRatingRequest, CreateCourseItemRatingResponse } from './types/create-course-item-rating';

export default class CourseItemRatingApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchCourseItemRatingRequest) {
        return await this.api.execute(request, FetchCourseItemRatingsResponse);
    }

    public async save(request: SaveCourseItemRatingRequest) {
        return await this.api.execute(request, SaveCourseItemRatingResponse);
    }

    public async create(request: CreateCourseItemRatingRequest) {
        return await this.api.execute(request, CreateCourseItemRatingResponse);
    }
}