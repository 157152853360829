import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class DeleteTaskAnswerCommentRequest extends ApiRequest {
    public method = HttpMethod.DELETE;
    public url: string = `/student-course-item/task-answer/comment/${this.commentId}`;

    constructor(public commentId: string) {
        super();
    }
}

export class DeleteTaskAnswerCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}