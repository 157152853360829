import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { CoursePlanFilter, CoursePlan } from '@axl/accel-framework/models';

export class FetchCoursePlansRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        name,
        disableTimeLimits,
        softDeleted
    }`;
    public url: string = `/course-preview/${this.courseId}/plans`;
    public body = {
        ...this.filter,
    }

    constructor(public courseId: string, public filter?: CoursePlanFilter, fields?: string) {
        super();
        this.fields = fields || this.fields;
    }
}

export class FetchCoursePlansResponse extends ApiResponse {
    public filter: CoursePlanFilter;
    public items: CoursePlan[];
    constructor(response: any) {
        super(response);
        this.filter = new CoursePlanFilter(this.body.filter);
        this.items = this.body.items.map(CoursePlan.fromJson);
    }
}