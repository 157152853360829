import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class LogoutRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/logout';
    public body: any = {
        token: this.refreshToken
    };
    public authorized = true;

    constructor(public refreshToken: string) {
        super();
    }
}

export class LogoutResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}