import { Updatable, Contact, GamificationAchievement } from '@axl/accel-framework/models';
import moment from 'moment';

export declare type NotificationType = 'gamificationAchievement';
export abstract class NotificationPayload extends Updatable {
    constructor(payload?: Partial<NotificationPayload>) {
        super();
        this.update(payload);
    }

    type: NotificationType;

    id: string;
    createdDate: moment.Moment;
    updatedDate?: moment.Moment;

    contact?: Contact;

    abstract get key(): string;

    static fromJsonBase(json: any) {
        return {
            id: json.id,
            type: json.type,
            createdDate: moment(json.createdDate),
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
        };
    }
}

export class GamificationAchievementPayload extends NotificationPayload {

    achievement: GamificationAchievement;

    constructor(payload?: Partial<GamificationAchievementPayload>) {
        super(payload);
        if (payload) this.update(payload);
    }

    get key(): string {
        return 'GamificationAchievementNotification';
    }

    static fromJson(json: any) {
        return new GamificationAchievementPayload({
            achievement: GamificationAchievement.fromJson(json.achievement),
            ...NotificationPayload.fromJsonBase(json),
        });
    }
}