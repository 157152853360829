import { SaveNotificationRequest, SaveNotificationResponse } from './types/save';
import { FetchNotificationsRequest, FetchNotificationsResponse } from './types/fetch';
import Api from 'api';
import { DeleteNotificationRequest, DeleteNotificationResponse } from './types/delete';
import NotificationSettingsApi from './Settings';
import { FetchCountNotificationsRequest, FetchCountNotificationsResponse } from './types/fetch-count';
import { MarkAllAsReadNotificationsRequest, MarkAllAsReadNotificationsResponse } from './types/mark-all-as-read';
import NotificationChannelApi from './Channel';

export default class NotificationApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchNotificationsRequest) {
        return await this.api.execute(request, FetchNotificationsResponse);
    }

    public async save(request: SaveNotificationRequest) {
        return await this.api.execute(request, SaveNotificationResponse);
    }

    public async delete(request: DeleteNotificationRequest) {
        return await this.api.execute(request, DeleteNotificationResponse);
    }

    public async fetchCount(request: FetchCountNotificationsRequest) {
        return await this.api.execute(request, FetchCountNotificationsResponse);
    }

    public async markAllAsRead(request: MarkAllAsReadNotificationsRequest) {
        return await this.api.execute(request, MarkAllAsReadNotificationsResponse);
    }

    public settings = new NotificationSettingsApi(this.api);
    public channel = new NotificationChannelApi(this.api);
}