import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { School } from 'models';

export class FetchSchoolSettingsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/school-setting`;

    constructor(fields?: string) {
        super();
        this.fields = fields || this.fields;
    }
}

export class FetchSchoolSettingsResponse extends ApiResponse {
    public school: School;
    constructor(response: any) {
        super(response);
        this.school = School.fromJson(this.body);
    }
}