import React, { useCallback, useEffect } from 'react';
import NotificationCenterStore from './NotificationCenterStore';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';
import { GamificationAchievementPayload, NotificationPayload } from 'hub-client/payloads/notifications';
import GamificationAchievementNotification from './Notifications/GamificationAchievement/GamificationAchievementNotification';

export function useNotificationCenter() {
    const store = useInstance(NotificationCenterStore);
    return store;
}

const NotificationCenter: React.FC = () => {
    const store = useInstance(NotificationCenterStore);

    useEffect(() => {
        store.start();
        // maybe we need to clear only specific notifications in future
        return () => store.stop(true);
    }, []);

    const renderNotification = useCallback((notification: NotificationPayload) => {
        switch (notification.type) {
            case 'gamificationAchievement':
                return <GamificationAchievementNotification
                    key={notification.key}
                    payload={notification as GamificationAchievementPayload}
                    onClose={() => store.close(notification.key)} />;
            default:
                console.warn(`[NotificationCenter] unknown notification ${notification.type}`);
                return null;
        }
    }, []);

    return <>
        {Array.from(store.notifications).map(([, value]) => renderNotification(value))}
    </>;
}
export default observer(NotificationCenter);