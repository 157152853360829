import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class SendFeedbackRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public body = {
        studentCourseId: this.studentCourseId,
        text: this.text
    }
    public url: string = `/student-course/send-feedback`;

    constructor(public studentCourseId: string, public text: string) {
        super();
    }
}

export class SendFeedbackResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}