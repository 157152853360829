import { LoadingOverlay } from '@axl/accel-framework/components';
import { LocalizationStore } from '@axl/accel-framework/stores';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { useLang } from 'components';

type Props = {}
const LocProvider: React.FC<Props> = ({ children }) => {
    const locStore = useInstance(LocalizationStore);

    const { lang } = useLang();

    const [ready, setReady] = useState(false);

    useEffect(() => {
        const targetLang = lang;
        if (!locStore.ready || locStore.lang != targetLang) {
            setReady(false);

            (async () => {
                await locStore.init(targetLang);
                setReady(true);
            })();
            return;
        }
        setReady(true);
    }, [lang]);

    if (!ready) return <LoadingOverlay position='absolute' />;
    return <>{children}</>;
}

export default observer(LocProvider);