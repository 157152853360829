import BaseApi, { ApiClient, FileApi, FileManagerApi, LocalizationApi } from '@axl/accel-framework/api';
import StudentCourseApi from './methods/student/student-course';
import SchoolCourseApi from './methods/school-course';
import StudentCourseItemApi from './methods/student/student-course-item';
import SchoolSettingsApi from './methods/school-settings/types/index';
import StudentApi from './methods/student/student';
import ServiceApi from './methods/service';
import CoursePreviewApi from './methods/course-preview';
import StudentCoursePreviewApi from './methods/student-course-preview';
import ForumApi from './methods/forum';
import StudentEmailBroadcastApi from './methods/student/email-broadcast';
import SchoolLibraryApi from './methods/school-library';
import CourseItemRatingApi from './methods/student/course-item-rating';
import CourseItemCommentApi from './methods/student/course-item-comment';
import AuthApi from './methods/auth';
import NotificationApi from './methods/Notification';
import PartnershipApi from './methods/partnership';
import { SchoolContentItem, SchoolContentItemFilter } from 'models';
import AnnouncementApi from './methods/announcement';
import GamificationApi from './methods/gamification';

export default class Api extends BaseApi {
    constructor(client: ApiClient) {
        super(client);
    }

    file: FileApi = new FileApi(this);
    fileManager: FileManagerApi = new FileManagerApi(this);
    loc: LocalizationApi = new LocalizationApi(this);
    auth = new AuthApi(this);
    student = new StudentApi(this);

    schoolContent = {
        fetch: (filter: SchoolContentItemFilter) => this.getList<SchoolContentItem>('/school-content-item', '', filter,
            (json: any) => {
                return {
                    items: json.items.map(SchoolContentItem.fromJson),
                    filter: new SchoolContentItemFilter(json.filter)
                }
            })
    }

    studentCourse = new StudentCourseApi(this);
    schoolCourses = new SchoolCourseApi(this);
    schoolLibraries = new SchoolLibraryApi(this);
    studentEmailBroadcast = new StudentEmailBroadcastApi(this);
    studentCourseItem = new StudentCourseItemApi(this);
    courseItemRating = new CourseItemRatingApi(this);
    courseItemComment = new CourseItemCommentApi(this);
    schoolSettings = new SchoolSettingsApi(this);
    service = new ServiceApi(this);
    coursePreview = new CoursePreviewApi(this);
    studentCoursePreview = new StudentCoursePreviewApi(this);
    forum = new ForumApi(this);
    notification = new NotificationApi(this);
    partnership = new PartnershipApi(this);
    announcement = new AnnouncementApi(this);
    gamification = new GamificationApi(this);
}