import { action, observable, computed } from 'mobx';
import { Entity, SchoolContentItemProgressRating } from '@axl/accel-framework/models';

export declare type CourseItemProgressStatus = 'default' | 'opened';
export declare type CourseItemProgressTaskStatus = 'default' | 'started' | 'pending' | 'completed';

export class StudentCourseItemBaseProgress extends Entity {
    @observable beginDate: Date | null;
    @observable completeDate: Date | null;
    @observable numberUncheckedTasks: number = 0;
    @observable numberCorrectTasks: number = 0;
    @observable numberIncorrectTasks: number = 0;
}

export default class StudentCourseItemProgress extends StudentCourseItemBaseProgress {
    constructor(progress?: Partial<StudentCourseItemProgress>) {
        super(progress);
        if (progress) this.update(progress);
    }

    @observable precompleteDate: Date | null;
    @observable theoryStatus?: boolean;
    @observable testStatus?: boolean;
    @observable taskStatus?: CourseItemProgressTaskStatus;
    @observable status: CourseItemProgressStatus;
    @observable rating: SchoolContentItemProgressRating;

    @computed get theoryCompleted() {
        return this.theoryStatus == true;
    }
    @computed get testsCompleted() {
        return this.testStatus == true;
    }
    @computed get tasksCompleted() {
        return this.taskStatus == 'completed';
    }

    static fromJson(json: any): StudentCourseItemProgress {
        return new StudentCourseItemProgress({
            ...json,
            beginDate: new Date(json.beginDate),
            completeDate: json.completeDate ? new Date(json.completeDate) : null,
            precompleteDate: json.precompleteDate ? new Date(json.precompleteDate) : null,
            rating: json.rating ? SchoolContentItemProgressRating.fromJson(json.rating) : null,
        });
    }

    @action update(progress: Partial<StudentCourseItemProgress>) {
        super.update(progress);
    }
}

export class StudentCourseSectionProgress extends StudentCourseItemBaseProgress {
    constructor(progress?: Partial<StudentCourseSectionProgress>) {
        super();
        if (progress) this.update(progress);
    }

    @action update(progress: Partial<StudentCourseSectionProgress>) {
        super.update(progress);
    }
}