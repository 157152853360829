import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ProlongSessionRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/prolong-session';
    public body: any = {
        token: this.refreshToken
    };
    public authorized = true;

    constructor(public refreshToken: string) {
        super();
    }
}

export class ProlongSessionResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}