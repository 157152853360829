import Api from 'api';
import { FetchCourseItemCommentsRequest, FetchCourseItemCommentsResponse } from './types/fetch-course-item-comments';
import { SaveCourseItemCommentRequest, SaveCourseItemCommentResponse } from './types/save-course-item-comment';
import { CreateCourseItemCommentRequest, CreateCourseItemCommentResponse } from './types/create-course-item-comment';
import { RemoveCourseItemCommentRequest, RemoveCourseItemCommentResponse } from './types/remove-course-item-comment';

export default class CourseItemCommentApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchCourseItemCommentsRequest) {
        return await this.api.execute(request, FetchCourseItemCommentsResponse);
    }

    public async save(request: SaveCourseItemCommentRequest) {
        return await this.api.execute(request, SaveCourseItemCommentResponse);
    }

    public async create(request: CreateCourseItemCommentRequest) {
        return await this.api.execute(request, CreateCourseItemCommentResponse);
    }

    public async remove(request: RemoveCourseItemCommentRequest) {
        return await this.api.execute(request, RemoveCourseItemCommentResponse);
    }
}