import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type CreateTaskAnswerCommentRequestModel = {
    answerId: string,
    comment: string,
    files: string[]
}
export class CreateTaskAnswerCommentRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/student-course-item/task-answer/comment`;
    public body = {
        ...this.model
    }

    constructor(public model: CreateTaskAnswerCommentRequestModel) {
        super();
    }
}

export class CreateTaskAnswerCommentResponse extends ApiResponse {
    id: string;
    constructor(response: any) {
        super(response);
        this.id = this.body;
    }
}