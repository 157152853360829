import { observable, action, computed } from 'mobx';
import { AccelFile, Course, CourseItemType, Entity, CoursePlan } from '@axl/accel-framework/models';
import { StudentCourseItemTest, StudentCourseItemTask, StudentCourse, StudentCourseItemProgress } from 'models';
import { FlowTimeDelayCondition, RegularTimeDelayCondition, CourseItemAvailability } from '@axl/accel-framework/models/course/item/course-item';
import type { Moment } from 'moment';
import moment from 'moment';
import { StudentCourseItemBaseProgress, StudentCourseSectionProgress } from './student-course-item-progress/student-course-item-progress';
import type { TimeMeasure } from '@axl/accel-framework/models';
import { isNil } from 'lodash';
import { TimeSpan } from '@axl/accel-framework/utils';

/**
 * DO NOT FORGET TO UPDATE TYPE IN SCHOOL SDK client\site\src\school-sdk\index.ts
 */
export declare type CourseItemTab = 'theory' | 'tests' | 'tasks' | 'rating';
export declare type CourseItemAction = 'select' | 'complete' | 'reset' | 'block' | 'unblock';

export type StudentCourseItemAvailability = {
    result: boolean,
    isSection?: boolean,
    willBeAvailableOn?: Moment,
    willBeAvailableIf?: { in?: TimeSpan, item?: StudentCourseItem, condition: 'afterStart' | 'afterComplete' };
    needToCompletePrevStep?: boolean,
    needToCompletePrevSteps?: boolean,
    blockingCourseItem?: StudentCourseItem,
    requiredPlans?: string[]
}

export default class StudentCourseItem extends Entity {
    constructor(item?: Partial<StudentCourseItem>) {
        super(item);
        this._tab = this.defaultTab;
        if (item) this.update(item);
    }

    @observable title: string;
    @observable position: number;
    @observable isDraft: boolean;
    @observable isOptional: boolean;
    @observable type: CourseItemType;
    @observable comment: string;

    // restrictions and availability
    @observable allowComments: boolean;
    @observable showComments: boolean; // TODO
    @observable disableCommentModeration: boolean; // TODO
    @observable ratingEnabled: boolean;
    @observable ratingRequired: boolean;
    @observable ratingCommentRequired: boolean;
    @observable availability: CourseItemAvailability;

    @observable disableTimeLimits: boolean;
    @observable regularTimeDelayActive: boolean;
    @observable regularTimeDelay: number;
    @observable regularTimeDelayCondition: RegularTimeDelayCondition;
    @observable regularTimeDelayMeasureType: TimeMeasure;

    @observable flowTimeDelayActive: boolean;
    @observable flowTimeDelay: number;
    @observable flowTimeDelayCondition: FlowTimeDelayCondition;
    @observable flowTimeDelayMeasureType: TimeMeasure;

    @observable dateDelayActive: boolean;
    @observable dateDelay: Moment;

    @observable preventCopingAndSelectingText: boolean; // TODO

    @observable designHTMLPublished: string;
    @observable designStylePublished: string;

    @observable blockCourseUntilTasksApproved: boolean;
    @observable theoryVisible: boolean;
    @observable tasksVisible: boolean;
    @observable testsVisible: boolean;
    @computed get ratingVisible(): boolean {
        return this.allowComments || this.ratingEnabled;
    }

    @observable score: number; // TODO

    @observable files: AccelFile[];

    @observable progress: StudentCourseItemProgress;
    @observable tests: StudentCourseItemTest[] = [];
    @observable tasks: StudentCourseItemTask[] = [];
    @observable requiredPlans: string[];

    @observable currentTest: StudentCourseItemTest | null = null;

    @observable parent: StudentCourseItem | null;
    @observable parentItemId?: string | null;

    @observable children: StudentCourseItem[] = [];

    @observable previousItem: StudentCourseItem | null;
    @observable nextItem: StudentCourseItem | null;
    @observable studentCourse: StudentCourse;
    @observable course: Course;

    @observable fetching: boolean;

    /** 
     * position regarding all steps
    */
    globalPosition: number;
    /**
     * level of the item in the tree
     */
    level: number;


    /**
     * returns the nearest previous section on the same level is exists
     */
    @computed get previousSection(): StudentCourseItem | null {
        let prevItemSection = this.previousItem?.parent ?? null;
        while (prevItemSection != null) {
            if (prevItemSection.parent?.id === this.parent?.id)
                return prevItemSection;
            prevItemSection = prevItemSection.parent ?? null;
        }
        return null;
    }

    @computed get firstAvailableTab() {
        if (this.theoryVisible) return 'theory';
        if (this.testsVisible) return 'tests';
        if (this.tasksVisible) return 'tasks';
        if (this.ratingVisible) return 'rating';
        return 'theory';
    }

    @computed get itemCount() {
        if (this.type == CourseItemType.Step) return 0;
        let count = this.children.length;
        for (const child of this.children) {
            count += child.itemCount;
        }
        return count;
    }

    @computed get itemCompletedCount() {
        if (this.type == CourseItemType.Step) return 0;
        let count = this.children.filter(x => x.completed).length;
        for (const child of this.children) {
            count += child.itemCompletedCount;
        }
        return count;
    }

    @computed get stepCount() {
        if (this.type == CourseItemType.Step) return 0;
        let count = this.children.count(x => x.type == CourseItemType.Step);
        for (const child of this.children) {
            count += child.stepCount;
        }
        return count;
    }

    @computed get taskCount() {
        if (this.type == CourseItemType.Step)
            return this.tasks.length;
        return this.children.reduce((a, b) => a + b.taskCount, 0);
    }

    @computed get taskCompletedCount() {
        if (this.type == CourseItemType.Step)
            return this.progress?.numberCorrectTasks ?? 0;
        return this.children.reduce((a, b) => a + b.taskCompletedCount, 0);
    }

    @computed get sectionProgress(): StudentCourseSectionProgress {
        const p = new StudentCourseSectionProgress();
        let isEveryChildCompleted = true;
        // use accessibleChildren to skip prohibited items due to plan access
        for (const child of this.accessibleChildren) {
            const progress: StudentCourseItemBaseProgress | undefined =
                child.type == CourseItemType.Section
                    ? child.sectionProgress
                    : child.progress;

            if (!progress) {
                isEveryChildCompleted = false;
                p.completeDate = null;
                continue;
            }

            p.numberCorrectTasks += progress.numberCorrectTasks;
            p.numberIncorrectTasks += progress.numberIncorrectTasks;
            p.numberUncheckedTasks += progress.numberUncheckedTasks;

            if (isEveryChildCompleted) {
                if (!progress.completeDate) {
                    isEveryChildCompleted = false;
                    p.completeDate = null;
                }
                else if (!p.completeDate || p.completeDate < progress.completeDate)
                    p.completeDate = progress.completeDate;
            }

            if (progress.beginDate) {
                if (!p.beginDate || p.beginDate > progress.beginDate)
                    p.beginDate = progress.beginDate;
            }
        }
        return p;
    }

    @computed get ratingCompleted() {
        if (!this.ratingEnabled || !this.ratingRequired) return true;
        // has required rating
        if (this.progress?.rating == null) return false;
        if (!this.ratingCommentRequired)
            return true;
        // comment required
        return this.progress.rating.comment?.length > 0;
    }

    @computed get completed(): boolean {
        if (this.type == CourseItemType.Step)
            return this.progress?.completeDate != null;
        return this.sectionProgress?.completeDate != null;
    }

    @computed get beginDate(): Date | null {
        if (this.type == CourseItemType.Step)
            return this.progress?.beginDate;
        return this.sectionProgress?.beginDate;
    }

    @computed get completeDate(): Date | null {
        if (this.type == CourseItemType.Step)
            return this.progress?.completeDate;
        return this.sectionProgress?.completeDate;
    }

    @computed get precompleted() { return this.progress?.precompleteDate != null; }
    @computed get fullPosition() {
        let position = '';
        let section = this.parent;
        while (section != null) {
            position += `${section.position}.`;
            section = section.parent;
        }
        position += this.position;
        return position;
    }

    @observable private _tab: CourseItemTab;
    @computed get tab() { return this._tab; }
    set tab(value: CourseItemTab) {
        switch (value) {
            case 'theory': {
                if (this.theoryVisible) {
                    this._tab = 'theory';
                    return;
                }
                break;
            }
            case 'tests': {
                if (this.testsVisible) {
                    this._tab = 'tests';
                    return;
                }
                break;
            }
            case 'tasks': {
                if (this.tasksVisible) {
                    this._tab = 'tasks';
                    return;
                }
                break;
            }
            case 'rating': {
                if (this.allowComments || this.ratingEnabled) {
                    this._tab = 'rating';
                    return;
                }
                break;
            }
        }
    }

    @computed get defaultTab(): CourseItemTab {
        if (this.progress == null) return 'theory';
        if (this.theoryVisible && !this.progress.theoryCompleted) return 'theory';
        else if (this.testsVisible && !this.progress.testsCompleted) return 'tests'
        else if (this.tasksVisible && !this.progress.tasksCompleted) return 'tasks';
        return 'theory';
    }

    @computed get canSelect() { return this.courseItemAvailability.result; }

    @computed get previousRequiredItem(): StudentCourseItem | null {
        if (this.previousAccessibleItem == null)
            return null;
        if (this.previousAccessibleItem.canSkip)
            return this.previousAccessibleItem.previousRequiredItem;
        return this.previousAccessibleItem;
    }

    @computed get previousAccessibleItem(): StudentCourseItem | null {
        if (this.previousItem == null)
            return null;
        if (!this.previousItem.hasPlanAccess)
            return this.previousItem.previousAccessibleItem;
        return this.previousItem;
    }

    /**
     * returns the next item regarding to plan access (skip unavalaible items)
     */
    @computed get nextAccessibleItem(): StudentCourseItem | null {
        if (this.nextItem == null)
            return null;
        if (!this.nextItem.hasPlanAccess)
            return this.nextItem.nextAccessibleItem;
        return this.nextItem;
    }

    @computed get hasAccessibleItems(): boolean {
        if (this.type != CourseItemType.Section || this.children.length == 0)
            return false;
        for (const child of this.children) {
            // skip unavaialble steps & sections (we can skip section due to strict inherited access to all children)
            if (!child.hasPlanAccess) continue;
            // no plan limit => if step available OR section has available step inside (recursion) return true
            if (child.type == CourseItemType.Step || child.hasAccessibleItems)
                return true;
        }
        // no avaialble steps
        return false;
    }

    /**
     * returns all children (sections and steps) that have plan access
     */
    @computed get accessibleChildren(): StudentCourseItem[] {
        return this.children.filter(i => i.hasPlanAccess);
    }

    hasAncestor(item: StudentCourseItem) {
        let parent = this.parent;
        while (parent) {
            if (parent == item) return true;
            parent = parent.parent;
        }
        return false;
    }

    @computed get courseItemAvailability(): StudentCourseItemAvailability {
        // if the step has progress then it's available by default
        if (this.progress != null && this.progress.status == 'opened')
            return { result: true };
        if (!this.hasPlanAccess)
            return { result: false, requiredPlans: this.requiredPlans }

        if (!this.availabilityRelativeToTimeDelay.result)
            return this.availabilityRelativeToTimeDelay;

        const parentAvailability = this.parentAvailability;
        // check parent section availability first
        if (!parentAvailability.result) {
            return { ...parentAvailability, isSection: true };
        }
        if (!this.availabilityRelativeToOther.result)
            return this.availabilityRelativeToOther;
        return { result: true };
    }

    @computed private get parentAvailability(): StudentCourseItemAvailability {
        if (this.parent != null)
            return this.parent.courseItemAvailability;
        // if it doesn't exist
        return { result: true };
    }

    @computed private get availabilityRelativeToTimeDelay(): StudentCourseItemAvailability {

        if (this.disableTimeLimits) return { result: true };

        const checkFlowTimeDelay = this.flowTimeDelayActive && this.studentCourse.isInFlow;
        if (checkFlowTimeDelay) {
            switch (this.flowTimeDelayCondition) {
                case FlowTimeDelayCondition.CourseStart: {
                    const now = moment();
                    const delay = moment(this.studentCourse.beginDate!)
                        .add(this.flowTimeDelayValue.milliseconds, 'milliseconds');
                    if (now <= delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case FlowTimeDelayCondition.FlowStart: {
                    if (this.studentCourse.flowBeginDate == null)
                        break;
                    const now = moment();
                    const delay = moment(this.studentCourse.flowBeginDate)
                        .add(this.flowTimeDelayValue.milliseconds, 'milliseconds');
                    if (now <= delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case FlowTimeDelayCondition.PreviousStep: {
                    if (!this.previousAccessibleItem)
                        break;
                    if (!this.previousAccessibleItem.precompleted)
                        return { result: false, needToCompletePrevStep: true };
                    const now = moment();
                    const delay = moment(this.previousAccessibleItem.progress.precompleteDate!)
                        .add(this.flowTimeDelayValue.milliseconds, 'milliseconds');
                    if (now < delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case FlowTimeDelayCondition.PreviousSectionStarted:
                case FlowTimeDelayCondition.PreviousSectionCompleted: {
                    const prevSection = this.previousSection;
                    if (prevSection && prevSection.hasAccessibleItems) {
                        const targetDate = this.flowTimeDelayCondition == FlowTimeDelayCondition.PreviousSectionStarted
                            ? prevSection.beginDate
                            : prevSection.completeDate;
                        if (targetDate) {
                            const now = moment();
                            const delay = moment(targetDate).add(this.regularTimeDelayValue.milliseconds, 'milliseconds');
                            if (now < delay)
                                return { result: false, willBeAvailableOn: delay };
                        } else
                            return {
                                result: false,
                                willBeAvailableIf: {
                                    in: this.regularTimeDelayValue,
                                    item: prevSection,
                                    condition: this.flowTimeDelayCondition == FlowTimeDelayCondition.PreviousSectionStarted ? 'afterStart' : 'afterComplete',
                                }
                            };
                    }
                    break;
                }
            }
        }

        if (this.regularTimeDelayActive) {
            switch (this.regularTimeDelayCondition) {
                case RegularTimeDelayCondition.AccessStart: {
                    const now = moment();
                    const delay = moment(this.studentCourse.accessBeginDate)
                        .add(this.regularTimeDelayValue, 'milliseconds');
                    if (now <= delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case RegularTimeDelayCondition.CourseStart: {
                    const now = moment();
                    const delay = moment(this.studentCourse.beginDate)
                        .add(this.regularTimeDelayValue, 'milliseconds');
                    if (now <= delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case RegularTimeDelayCondition.PreviousStep: {
                    if (!this.previousAccessibleItem)
                        break;
                    if (!this.previousAccessibleItem.precompleted)
                        return { result: false, needToCompletePrevStep: true };
                    const now = moment();
                    const delay = moment(this.previousAccessibleItem.progress.precompleteDate!)
                        .add(this.regularTimeDelayValue.milliseconds, 'milliseconds');
                    if (now < delay)
                        return { result: false, willBeAvailableOn: delay };
                    break;
                }
                case RegularTimeDelayCondition.PreviousSectionStarted:
                case RegularTimeDelayCondition.PreviousSectionCompleted: {
                    const prevSection = this.previousSection;
                    if (prevSection && prevSection.hasAccessibleItems) {
                        const targetDate = this.regularTimeDelayCondition == RegularTimeDelayCondition.PreviousSectionStarted
                            ? prevSection.beginDate
                            : prevSection.completeDate;
                        if (targetDate) {
                            const now = moment();
                            const delay = moment(targetDate).add(this.regularTimeDelayValue.milliseconds, 'milliseconds');
                            if (now < delay)
                                return { result: false, willBeAvailableOn: delay };
                        } else
                            return {
                                result: false,
                                willBeAvailableIf: {
                                    in: this.regularTimeDelayValue,
                                    item: prevSection,
                                    condition: this.regularTimeDelayCondition == RegularTimeDelayCondition.PreviousSectionStarted ? 'afterStart' : 'afterComplete',
                                }
                            };
                    }
                    break;
                }
            }
        }

        const useDateDelay = this.dateDelayActive && this.dateDelay != null;
        if (useDateDelay) {
            const available = moment().isAfter(this.dateDelay);
            if (!available)
                return { result: false, willBeAvailableOn: this.dateDelay };
        }

        return { result: true };
    }

    @computed private get availabilityRelativeToOther(): StudentCourseItemAvailability {
        switch (this.availability) {
            case CourseItemAvailability.RegardlessOfPreviousSteps: {
                // if there is any previous item which is NOT completed
                // AND blocks the course until all it's tasks are finished
                let prevRequiredItem = this.previousRequiredItem;
                while (prevRequiredItem) {
                    if (prevRequiredItem.blockCourseUntilTasksApproved == true) {
                        if (prevRequiredItem.completed == false) {
                            // if the next item has progress, then the previous item doesn't block the others
                            if (prevRequiredItem.nextAccessibleItem?.progress != null) break;
                            return { result: false, blockingCourseItem: prevRequiredItem };
                        }
                        break;
                    }
                    prevRequiredItem = prevRequiredItem.previousRequiredItem;
                }
                break;
            }
            case CourseItemAvailability.AllPreviousStepsCompleted: {
                let previousItem = this.previousRequiredItem;
                while (previousItem) {
                    if (previousItem.blockCourseUntilTasksApproved == true && previousItem.completed == false) {
                        return { result: false, blockingCourseItem: previousItem };
                    }
                    if (previousItem.blockCourseUntilTasksApproved == false && previousItem.precompleted == false) {
                        return { result: false, blockingCourseItem: previousItem };
                    }
                    previousItem = previousItem.previousRequiredItem;
                }
                break;
            }
            case CourseItemAvailability.PreviousStepCompleted: {
                if (!this.previousRequiredItem)
                    break;
                if (this.previousRequiredItem.blockCourseUntilTasksApproved == true && this.previousRequiredItem.completed == false) {
                    return { result: false, blockingCourseItem: this.previousRequiredItem };
                }
                if (this.previousRequiredItem.blockCourseUntilTasksApproved == false && this.previousRequiredItem.precompleted == false) {
                    return { result: false, blockingCourseItem: this.previousRequiredItem };
                }
                break;
            }
        }
        return { result: true };
    }

    @computed get hasPlanAccess() {
        return this.hasAccessGrantedByAdmin || this.requiredPlans?.length == 0;
    }

    @computed get hasAccessGrantedByAdmin(): boolean {
        if (this.type == CourseItemType.Step) {
            return this.progress?.status == 'opened';
        } else {
            return this.children.some(i => i.hasAccessGrantedByAdmin);
        }
    }

    @computed get canSkip() {
        return this.isOptional == true || !this.hasPlanAccess;
    }

    static fromJson(json: any): StudentCourseItem {
        const studentCourseItem = new StudentCourseItem({
            ...json,
            plans: json.plans ? json.plans.map((x: any) => CoursePlan.fromJson(x.coursePlan)) : undefined,
            parent: json.parentItem ? StudentCourseItem.fromJson(json.parentItem) : undefined,
            children: json.children ? json.children.map((x: any) => StudentCourseItem.fromJson(x)) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            files: json.files ? json.files.map((x: any) => AccelFile.fromJson(x)) : [],
            progress: json.progress ? StudentCourseItemProgress.fromJson(json.progress) : undefined,
            tests: [],
            tasks: [],
            dateDelay: json.dateDelay ? moment(json.dateDelay) : undefined,
        });
        if (json.tasks)
            studentCourseItem.tasks = json.tasks.map((x: any) => StudentCourseItemTask.fromJson(x, studentCourseItem));
        if (json.tests)
            studentCourseItem.tests = json.tests.map((x: any) => StudentCourseItemTest.fromJson(x, studentCourseItem));
        return studentCourseItem;
    }

    @action
    update(course: Partial<StudentCourseItem>) {
        super.update(course);
    }

    @computed private get regularTimeDelayValue() {
        if (isNil(this.regularTimeDelay))
            return TimeSpan.zero;

        return TimeSpan.fromTimeMeasure(this.regularTimeDelay, this.regularTimeDelayMeasureType ?? 'days');
    }

    @computed private get flowTimeDelayValue() {
        if (isNil(this.flowTimeDelay))
            return TimeSpan.zero;

        return TimeSpan.fromTimeMeasure(this.flowTimeDelay, this.flowTimeDelayMeasureType ?? 'days');
    }
}

