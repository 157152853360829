import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export type TrackFormModel = {
    courseId?: string;
    courseItemId?: string;
    libraryItemId?: string;
    libraryId?: string;
    name?: string;
    fields: any;
}

export class TrackFormRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/t/f`;

    public body = {
        ...this.model
    }

    constructor(public model: Partial<TrackFormModel>) {
        super();
    }
}

export class TrackFormResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}