import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseProgress } from 'models';

export class CompleteStudentCourseItemRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/student-course-preview/item/complete`;
    public body = {
        studentCourseId: this.studentCourseId,
        courseItemId: this.courseItemId
    }

    constructor(public studentCourseId: string, public courseItemId: string) {
        super();
    }
}

export class CompleteStudentCourseItemResponse extends ApiResponse {
    progresses: StudentCourseProgress[];
    constructor(response: any) {
        super(response);
        this.progresses = this.body.map(StudentCourseProgress.fromJson);
    }
}