export default class QuizSettings {

    public styleUrl: string;
    public scriptUrl: string;
    public apiUrl: string;

    constructor(settings: any) {
        this.styleUrl = settings.styleUrl;
        this.scriptUrl = settings.scriptUrl;
        this.apiUrl = settings.apiUrl;
    }
}