import { computed, observable, action } from 'mobx';
import { TokenStore } from '@axl/accel-framework/stores';
import { Student } from 'models';
import { AuthCookieClient, JwtDecoder } from '@axl/accel-framework/utils';

export default class UserStore extends TokenStore {

    constructor(jwtDecoder: JwtDecoder,
        cookieClient: AuthCookieClient) {
        super(jwtDecoder, cookieClient);
    }

    @observable currentUser: Student;
    @action updateUser(user: Student) {
        this.currentUser = user;
    }

    @observable gamificationScore: number;
    @action setGamificationBalance(score: number) {
        this.gamificationScore = score;
    }

    /**
     * isAuthenticated & current user fetched
     */
    @computed get isAuthorized() {
        return this.isAuthenticated && this.currentUser != null;
    }

    /**
     * has an access token
     */
    @computed get isAuthenticated() {
        return this.decodedAccessToken != null;
    }

    @action reset() {
        this.clearTokens();
        // @ts-ignore
        this.currentUser = null;
    }
}