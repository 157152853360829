import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export class MarkAllAsReadNotificationsRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/notification/markAllAsRead`;

    constructor() {
        super();
    }
}

export class MarkAllAsReadNotificationsResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}