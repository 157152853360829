import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { SchoolLibraryItem, SchoolLibraryItemFilter } from 'models';

export class FetchSchoolLibraryItemsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        title,
        type,
        flowTimeDelayActive,
        flowTimeDelay,
        flowTimeDelayCondition,
        flowTimeDelayMeasureType,
        preventCopingAndSelectingText,
        allowComments,
        showComments,
        ratingEnabled,
        ratingCommentRequired,
        studentHasAccess,
        position,
        parentItem {
            id,
        }
        progress {
            id
        }
    }`;

    public url: string = `/school-library-item`;

    constructor(public libraryId: string) {
        super();
        this.body = {
            ... new SchoolLibraryItemFilter({ libraryId: libraryId, useBaseFilter: false })
        };
    }
}

export class FetchSchoolLibraryItemsResponse extends ApiResponse {
    items: SchoolLibraryItem[];
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(SchoolLibraryItem.fromJson);
    }
}