import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { combineClasses } from '@axl/accel-framework/utils';

type PageContentProps = {
    margin?: string;
    backgroundColor?: string;
    transparent?: boolean;
    className?: string;
    flex1?: boolean;
    id?: string;
}

const PageContent: React.FC<PageContentProps> = ({ margin, backgroundColor, transparent, id, flex1, className, children }) => {

    const customStyles = useMemo(() => {
        const styles: React.CSSProperties = {};
        if (transparent == true || backgroundColor)
            styles.backgroundColor = transparent == true ? 'transparent' : backgroundColor;
        if (margin)
            styles.margin = margin;
        return styles;
    }, [margin, backgroundColor, transparent]);

    return <div id={id}
        className={combineClasses(flex1 && 'flex-1', 'bg-white', 'm-24', className)}
        style={customStyles}>
        {children}
    </div>;
}

PageContent.defaultProps = {
    transparent: false,
    flex1: true
}

export default observer(PageContent);