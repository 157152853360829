import { BaseFilter } from '@axl/accel-framework/models';
import { SortingDirection } from '@axl/accel-framework/enums';
import SchoolLibraryItem from './school-library-item';

export default class SchoolLibraryItemFilter extends BaseFilter<SchoolLibraryItem> {
    constructor(filter?: Partial<SchoolLibraryItemFilter>) {
        super();
        this.sortName = 'position';
        this.sortType = SortingDirection.asc;
        if (filter) this.update(filter);
    }
    libraryId: string;
    /**
     * Item we'd like to select
     * In addition to root branch, request the item section branch
     */
    libraryItemId: string;
    /**
     * Request only the section items
     */
    sectionId: string;

    update(changes: Partial<SchoolLibraryItemFilter>) {
        super.update(changes);
    }
}