import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItemFilter, StudentCourseItem } from 'models';

export class FetchStudentCourseItemsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/student-course-item';
    public body = {
        ...this.filter,
    }
    public fields = `{
        id,
        parentItemId,
        title,
        type,
        position,
        availability,
        disableTimeLimits,
        regularTimeDelayActive,
        regularTimeDelay,
        regularTimeDelayCondition,
        regularTimeDelayMeasureType,
        flowTimeDelayActive,
        flowTimeDelay,
        flowTimeDelayCondition,
        flowTimeDelayMeasureType,
        dateDelayActive,
        dateDelay,
        preventCopingAndSelectingText,
        blockCourseUntilTasksApproved,
        isOptional,
        allowComments,
        showComments,
        ratingEnabled,
        ratingRequired,
        ratingCommentRequired,
        theoryVisible,
        testsVisible,
        tasksVisible,
        requiredPlans,
        progress{
            id,
            beginDate,
            completeDate,
            precompleteDate,
            status,
            theoryStatus,
            testStatus,
            taskStatus,
            numberUncheckedTasks,
            numberCorrectTasks,
            numberIncorrectTasks,
        },
        tasks{
            id,
        }
    }`;


    constructor(public filter?: StudentCourseItemFilter) {
        super();
    }
}

export class FetchStudentCourseItemsResponse extends ApiResponse {
    public filter: StudentCourseItemFilter;
    public items: StudentCourseItem[];
    constructor(response: any) {
        super(response);
        this.filter = new StudentCourseItemFilter(this.body.filter);
        this.items = this.body.items.map(StudentCourseItem.fromJson);
    }
}