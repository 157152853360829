import React, { useState } from 'react';
import { Menu, Button, Dropdown, Tooltip, Drawer } from 'antd';
import { useInstance } from 'react-ioc';
import { InfoOutlined } from '@ant-design/icons';
import styles from './DevActions.module.scss';
import { combineClasses } from '@axl/accel-framework/utils';
import ServiceStore from 'stores/ServiceStore/ServiceStore';
import { useDrawer } from '@axl/accel-framework/components';
import { useLoc } from 'components';
import MissingWords from './MissingWords/MissingWords';

const DevActions: React.FC = () => {
    const serviceStore = useInstance(ServiceStore);
    const loc = useLoc();
    const missingWordsDrawer = useDrawer<{
        project?: any;
        words?: any[];
    }>();

    const menu = <Menu>
        <Menu.Item key='updateLocalization' onClick={() => serviceStore.updateLocalization()}>Update localization</Menu.Item>
        <Menu.Item key='recentMissingWords'
            onClick={() => missingWordsDrawer.open({
                words: Array.from(loc.missingWords.keys()).map(x => ({ key: x, default: loc.missingWords.get(x) })) as any[],
                project: 'admin'
            })}>
            Recent missing words
        </Menu.Item>
    </Menu>;
    return <>
        <Tooltip title={'Dev menu'} placement='leftBottom'>
            <Dropdown overlay={menu} placement='topRight'>
                <Button type='default' shape='circle' icon={<InfoOutlined />} className={combineClasses(styles.dev_actions_btn, 'fixed')}></Button>
            </Dropdown>
        </Tooltip>

        <Drawer open={missingWordsDrawer.visible}
            onClose={() => missingWordsDrawer.close()}
            title={'Missing words'}
            width={1200}
            keyboard={false}
            destroyOnClose={true}>
            <MissingWords languages={[]}
                project={missingWordsDrawer.data?.project}
                words={missingWordsDrawer.data?.words}
                onCancel={() => missingWordsDrawer.close()} />
        </Drawer>
    </>;
}
export default DevActions;